import React from 'react'
import Header from '../Components/Header'
import Orders from '../Components/Orders'

export default function Orderpage() {
  return (
    <div><div id="layout-wrapper">
        <Header/>
        <Orders/>
        </div>
    </div>
  )
}
