import React ,{useState ,useEffect} from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import logo from '../Images/logo-darks.png';
import us from '../Images/flags/us.svg';
import arabic from '../Images/flags/ae.svg';
import user1 from '../Images/users/avatar-1.jpg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

export default function Header() {


  
        const languageOptions = [
            { value: 'ENG', label: 'English', flagUrl:us},
            { value: 'AR', label: 'Arabic', flagUrl: arabic },
          ];

            const { i18n } = useTranslation();
           
            const [dropdownVisible, setDropdownVisible] = useState(false);
         
    const navigate = useNavigate();
    const changeLanguage = (selectedLanguage) => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
        setDropdownVisible(false);
      };
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    id: '',
    firstName: '',
    email: '',
    userType:''
   
  });
  useEffect(() => {
    const fetchUserData = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('Access token not found');
        return;
      }

      try {
        const userId = getUserIdFromToken(token);
        if (!userId) {
          console.error('Failed to extract user ID from access token.');
          return;
        }

        console.log('User ID:', userId);

        const response = await axios.get(`https://tailors-api.azurewebsites.net/api/Users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const user = response.data; // Assuming single user data is returned
        setFormData({
          id: user.id,
          firstName: user.firstName,
          email: user.email,
          userType :user.userType
        });
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    fetchUserData();
  }, []); // Fetch data only once on component mount
  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.userid; // Assuming 'userid' is the custom claim in the token payload
      return userId;
    } catch (error) {
      console.error('Error decoding access token:', error);
      return null;
    }
  };
  
    const handleLogout = () => {
        
        googleLogout();
      
        
        navigate('/login', { replace: true });
      };
      const Submitorder=() =>{
                
        navigate('/order',{replace:true})
    }
    const Dashboard=() =>{
                
        navigate('/Dash',{replace:true})
    }
    const services=() =>{
                
        navigate('/service',{replace:true})
    }
    const tailor=() =>{
                
        navigate('/Tailor',{replace:true})
    }
    const NewOrder=() =>{
                
        navigate('/NewOrder',{replace:true})
    }
const Customer
=() =>{
                
    navigate('/Customer',{replace:true})
}
const Profile=() =>{
                
    navigate('/Profile',{replace:true})
}
const tailororder=() =>{
                
    navigate('/tailorOrder',{replace:true})
}

  return (
    <div>
        <header id="page-topbar">
<div className="layout-width">
<div className="navbar-header">
    <div className="d-flex">
       
        <div className="navbar-brand-box horizontal-logo">
            <a href="" className="logo logo-dark">
                <span className="logo-sm">
                    <img  alt="" height="22"/>
                </span>
               
            </a>

            <a href="index.html" className="logo logo-light">
                <span className="logo-sm">
                    <img  alt="" height="22"/>
                </span>
                <span className="logo-lg">
                    <img  alt="" height="17"/>
                </span>
            </a>
        </div>

        <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon">
            <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </button>

        
        <form className="app-search d-none d-md-block">
            <div className="position-relative">
                <input type="text" className="form-control" placeholder="Search..." autoComplete="off" id="search-options" value=""/>
                <span className="mdi mdi-magnify search-widget-icon"></span>
                <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
            </div>
            <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                <div data-simplebar style={{maxHeight: "320px"}}>
                
                    <div className="dropdown-header">
                        <h6 className="text-overflow text-muted mb-0 text-uppercase">Recent Searches</h6>
                    </div>

                    <div className="dropdown-item bg-transparent text-wrap">
                        <a href="index.html" className="btn btn-soft-secondary btn-sm rounded-pill">how to setup <i className="mdi mdi-magnify ms-1"></i></a>
                        <a href="index.html" className="btn btn-soft-secondary btn-sm rounded-pill">buttons <i className="mdi mdi-magnify ms-1"></i></a>
                    </div>
                  
                    <div className="dropdown-header mt-2">
                        <h6 className="text-overflow text-muted mb-1 text-uppercase">Pages</h6>
                    </div>

                
                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <i className="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
                        <span>Analytics Dashboard</span>
                    </a>

                 
                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <i className="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                        <span>Help Center</span>
                    </a>

                 
                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <i className="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
                        <span>My account settings</span>
                    </a>

                  
                    <div className="dropdown-header mt-2">
                        <h6 className="text-overflow text-muted mb-2 text-uppercase">Members</h6>
                    </div>

                    <div className="notification-list">
                        
                        <a href="javascript:void(0);" className="dropdown-item notify-item py-2">
                            <div className="d-flex">
                                <img src="assets/images/users/avatar-2.jpg" className="me-3 rounded-circle avatar-xs" alt="user-pic"/>
                                <div className="flex-grow-1">
                                    <h6 className="m-0">Angela Bernier</h6>
                                    <span className="fs-11 mb-0 text-muted">Manager</span>
                                </div>
                            </div>
                        </a>
                     
                        <a href="javascript:void(0);" className="dropdown-item notify-item py-2">
                            <div className="d-flex">
                                <img src="assets/images/users/avatar-3.jpg" className="me-3 rounded-circle avatar-xs" alt="user-pic"/>
                                <div className="flex-grow-1">
                                    <h6 className="m-0">David Grasso</h6>
                                    <span className="fs-11 mb-0 text-muted">Web Designer</span>
                                </div>
                            </div>
                        </a>
                      
                        <a href="javascript:void(0);" className="dropdown-item notify-item py-2">
                            <div className="d-flex">
                                <img src="assets/images/users/avatar-5.jpg" className="me-3 rounded-circle avatar-xs" alt="user-pic"/>
                                <div className="flex-grow-1">
                                    <h6 className="m-0">Mike Bunch</h6>
                                    <span className="fs-11 mb-0 text-muted">React Developer</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="text-center pt-3 pb-1">
                    <a href="pages-search-results.html" className="btn btn-primary btn-sm">View All Results <i className="ri-arrow-right-line ms-1"></i></a>
                </div>
            </div>
        </form>
    </div>

    <div className="d-flex align-items-center">

        <div className="dropdown d-md-none topbar-head-dropdown header-item">
            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="bx bx-search fs-22"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
                <form className="p-3">
                    <div className="form-group m-0">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username"/>
                            <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    <div className={`dropdown ms-1 topbar-head-dropdown header-item ${dropdownVisible ? 'active' : ''}`}>
  <button className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none" onClick={() => setDropdownVisible(!dropdownVisible)}>
    <img id="header-lang-img" src={languageOptions.find(option => option.value === i18n.language)?.flagUrl} alt={`${i18n.language}-flag`} height="20" className="rounded" />
  </button>
  <div style={{ position: 'absolute', top: '100%', right: 0, backgroundColor: '#f9f9f9', border: '1px solid #ddd', padding: '10px 0', display: dropdownVisible ? 'block' : 'none', zIndex: 1 }}>
    <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
      {languageOptions.map((option, index) => (
        <li key={option.value} style={{ padding: '10px 20px', cursor: 'pointer' }} className="dropdown-item notify-item language py-2" onClick={() => changeLanguage(option.value)}>
          <img src={option.flagUrl} alt={`${option.value}-flag`} className="me-2 rounded" height="18" />
          <span className="align-middle">{option.label}</span>
        </li>
      ))}
    </ul>
  </div>
</div>


        <div className="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bx-category-alt fs-22'></i>
            </button>
           

     

        <div className="ms-1 header-item d-none d-sm-flex">
            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none" data-toggle="fullscreen">
                <i className='bx bx-fullscreen fs-22'></i>
            </button>
        </div>

        <div className="ms-1 header-item d-none d-sm-flex">
            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none">
                <i className='bx bx-moon fs-22'></i>
            </button>
        </div>

        <div className="dropdown ms-sm-3 header-item topbar-user">
            <button type="button" className="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <span className="d-flex align-items-center">
                    <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar"/>
                    <span className="text-start ms-xl-2">
                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{formData.firstName}</span>
                        <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{formData.email}</span>
                    </span>
                </span>
            </button>
            <div className="dropdown-menu dropdown-menu-end">
            
                <h6 className="dropdown-header">Welcome {formData.firstName}!</h6>
                <a className="dropdown-item" href="" onClick={()=>Profile()}><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" ></i> <span className="align-middle">Profile</span></a>
                <a className="dropdown-item" href="apps-chat.html"><i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Messages</span></a>
                <a className="dropdown-item" href="apps-tasks-kanban.html"><i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Taskboard</span></a>
                <a className="dropdown-item" href="pages-faqs.html"><i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Help</span></a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="pages-profile.html"><i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Balance : <b>$5971.67</b></span></a>
                <a className="dropdown-item" href="pages-profile-settings.html"><span className="badge bg-success-subtle text-success mt-1 float-end">New</span><i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Settings</span></a>
                <a className="dropdown-item" href="auth-lockscreen-basic.html"><i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></a>
                <a className="dropdown-item" href="" onClick={() => handleLogout()}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span></a>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</header>

<div className="app-menu navbar-menu">
 
    <div className="navbar-brand-box">
     
        <a href="" className="logo logo-dark">
            <span className="logo-sm">
                <img src= {logo} alt="" height="22"/>
            </span>
            <span className="logo-lg">
                <img src={logo} height="100px"  width="100px"/>
            </span>
        </a>
  
        <a href="index.html" className="logo logo-light">
            <span className="logo-sm">
                <img src="assets/images/logo-sm.png" alt="" height="22"/>
            </span>
            <span className="logo-lg">
                <img src="assets/images/logo-light.png" alt="" height="17"/>
            </span>
        </a>
        <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
            <i className="ri-record-circle-line"></i>
        </button>
    </div>

    <div id="scrollbar">
        <div className="container-fluid">

            <div id="two-column-menu">
            </div>
            <ul className="navbar-nav" id="navbar-nav">
                <li className="menu-title"><span data-key="t-menu">{t('Menu')}</span></li>
                <li className="nav-item">
                    <a className="nav-link menu-link" href="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards" onClick={()=>Dashboard()}>
                        <i className="mdi mdi-speedometer"></i> <span data-key="t-dashboards">{t('Dashboard')}</span>
                    </a>
                   
                </li> 
                <li className="nav-item">
                    <a className="nav-link menu-link" href=" " data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps" onClick={()=> services()}>
                        <i className="mdi mdi-tools"></i> <span data-key="t-apps">{t("Service")}</span>
                    </a>
                </li>
            
 
                {(formData.userType === 'client') && <li className="nav-item">
    <a
      className="nav-link menu-link"
      href=" "
      data-toggle="collapse"
      role="button"
      aria-expanded="false"
      aria-controls="sidebarLayouts"
      onClick={() => Submitorder()}
    >
      <i className="mdi mdi-cart-outline"></i> <span data-key="t-layouts">{t("Order")}</span>
    </a>
  </li>}
  {(formData.userType === 'tailor') && <li className="nav-item">
    <a
      className="nav-link menu-link"
      href=" "
      data-toggle="collapse"
      role="button"
      aria-expanded="false"
      aria-controls="sidebarLayouts"
      onClick={() => tailororder()}
    >
      <i className="mdi mdi-cart-outline"></i> <span data-key="t-layouts">{t("Order")}</span>
    </a>
  </li>}
  {formData.userType ==='customer' &&<li className="nav-item">
    <a
      className="nav-link menu-link"
      href=" "
      data-toggle="collapse"
      role="button"
      aria-expanded="false"
      aria-controls="sidebarLayouts"
      onClick={() => NewOrder()}
    >
      <i className="mdi mdi-cart-outline"></i> <span data-key="t-layouts">{t("Order")}</span>
    </a>
  </li>}

                {formData.userType === 'client'  &&<li className="nav-item">
                    <a className="nav-link menu-link" href="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAuth" onClick={()=>Customer()}>
                        <i className="mdi mdi-account-circle-outline"></i> <span data-key="t-authentication">{t("Customer")}</span>
                    </a>
                </li>}

                 <li className="nav-item">
                    <a className="nav-link menu-link" href="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPages">
                        <i className="mdi mdi-ruler"></i> <span data-key="t-pages">{t("Measurement")}</span>
                    </a>
                </li>

                {(formData.userType === 'client' || formData.userType === 'customer') &&<li className="nav-item">
                    <a className="nav-link menu-link" href="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarLanding">
                        <i className="mdi mdi-credit-card-outline"></i> <span data-key="t-landing">{t("Payment")}</span>
                    </a>
                    
                </li>}

               

                {formData.userType === 'client'  && <li className="nav-item">
                    <a className="nav-link menu-link" href="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarUI">
                        <i className="mdi mdi-currency-usd-off"></i> <span data-key="t-base-ui">{t("Expense")}</span>
                    </a>
                   
                </li>}

                {formData.userType === 'client'  && <li className="nav-item">
                    <a className="nav-link menu-link" href="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAdvanceUI" onClick={()=> tailor()}>
                        <i className="mdi mdi-needle"></i> <span data-key="t-advance-ui">{t("Tailor")}</span>
                    </a>
                </li>}

            </ul>
        </div>
   
    </div>

    <div className="sidebar-background" style={{backgroundColor:"green"}}></div>
</div>

<div className="vertical-overlay"></div>
    </div>
  )
}
