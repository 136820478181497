import React from 'react'
import Profile from '../Components/Profile'
import Header from '../Components/Header'

export default function () {
  return (
    <div>  <div id="layout-wrapper">
        <Header/>
        <Profile/>
        </div>
    </div>
  )
}
