import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from 'jwt-decode';
function Service() {
    const [searchQuery, setSearchQuery] = useState('');
    const initialServiceData = {
      code: '',
      name: '',
      price: '',
      description: ''
    };
  
    const [services, setServices] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [newServiceData, setNewServiceData] = useState(initialServiceData);
    const [filteredService, setFilteredServices] = useState([]);
    const [userCount, setUserCount] = useState(0);
    useEffect(() => {
      const fetchServices = async () => {
        try {
          const token = Cookies.get('accessToken');
          if (!token) {
            console.error('Access token not found');
            return;
          }
          const getUserIdFromToken = (token) => {
            try {
                // Decode the JWT token to get the payload
                const decodedToken = jwtDecode(token);
        
                // Extract the userid from the decoded payload
                const userId = decodedToken.userid; // Assuming 'userid' is the custom claim in the token payload
        
                return userId;
            } catch (error) {
                console.error('Error decoding access token:', error);
                return null;
            }
        };
        const userId = getUserIdFromToken(token);
  
  if (userId) {
      console.log('User ID:', userId);
      // Use the extracted userId in your frontend logic (e.g., to determine user-specific actions)
  } else {
      console.error('Failed to extract user ID from access token.');
  }
          const response = await axios.get('https://tailors-api.azurewebsites.net/api/Users/api/services', {
            headers: { Authorization: `Bearer ${token}` },
  });
          const ServiceData = response.data;
          setServices(ServiceData);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
      fetchServices();
    }, []);
  
  
  
    useEffect(() => {
      if (!services || !Array.isArray(services)) {
        setFilteredServices([]);
        return;
      }
    
      const filtered = services.filter((services) => {
        const lowerSearchQuery = searchQuery.toLowerCase();
        // Check if the user's email or name (firstName or userName) matches the search query
        const matchesName = services.name.toLowerCase().includes(lowerSearchQuery); // Assuming firstName field is used
    
        return  matchesName;
      });
    
      setFilteredServices(filtered);
    }, [searchQuery, services]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewServiceData({ ...newServiceData, [name]: value });
    };
  
    const handleAddUser = async () => {
        try {
            const token = Cookies.get('accessToken');
            if (!token) {
              console.error('Access token not found');
              return;
            }
            const response = await axios.post('https://tailors-api.azurewebsites.net/addService', newServiceData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setServices([...services, response.data]);
            setNewServiceData(initialServiceData);
            const modal = document.getElementById('addUserModal');
            const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
            modal.classList.remove('show');
            modal.setAttribute('aria-modal', 'false');
            document.body.classList.remove('modal-open');
            modalBackdrop.parentNode.removeChild(modalBackdrop);
            toast.success("Service Added successfully")
           
          } catch (error) {
            console.error('Error adding user:', error);
          }
      
        
        };
  
    const handleEditUser = async () => {
      if (!editingUser) return;
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('Access token not found');
        return;
      }
      try {
        const response = await axios.put(`https://tailors-api.azurewebsites.net/updateService/${(editingUser.id)}`, newServiceData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedService = services.map((service) => {
          if (service === editingUser) {
            return { ...service, ...newServiceData };
          }
          return service;
        });
        setServices(updatedService);
        setEditingUser(null);
        setNewServiceData(initialServiceData);
        // Close the modal after saving changes
        const modal = document.getElementById('addUserModal');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        modal.classList.remove('show');
        modal.setAttribute('aria-modal', 'false');
        document.body.classList.remove('modal-open');
        modalBackdrop.parentNode.removeChild(modalBackdrop);
        toast.success("Service updated successfully")
      } catch (error) {
          console.error('Error editing user:', error);
          toast.error('Failed to update service');
      }
    };
    
    
  
    const handleDeleteUser = async (serviceId) => {
        try {
          const token = Cookies.get('accessToken');
          if (!token) {
            console.error('Access token not found');
            return;
          }
    
          await axios.delete(`https://tailors-api.azurewebsites.net/deleteService/${serviceId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
    
          const updatedServices = services.filter((service) => service.id !== serviceId);
          setServices(updatedServices);
    
          // Notify the user that the service was deleted successfully
          toast.success('Service deleted successfully');
        } catch (error) {
          console.error('Error deleting service:', error);
          toast.error('Failed to delete service');
        }
      };
  
    const openEditModal = (service) => {
      setEditingUser(service);
      setNewServiceData(service);
    };
  
    const closeEditModal = () => {
      setEditingUser(null);
      setNewServiceData(initialServiceData);
    }
    const generateCode = (name) => {
        // Provide a default value if name is undefined or null
   
    
        // Remove non-alphanumeric characters and convert to uppercase
        const sanitized = name.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        // Generate a unique identifier (e.g., a random number)
        const uniqueIdentifier = Math.floor(Math.random() * 10000);
        // Combine the sanitized name and unique identifier
        return `${sanitized}-${uniqueIdentifier}`;
      };

    
    
      const handleNameChange = (e) => {
        const { value } = e.target;
        const generatedCode = generateCode(value); // Generate code
        setNewServiceData({
          ...newServiceData,
          name: value,
          code: generatedCode // Save generated code
        });
      };
      const [formData, setFormData] = useState({
        id: '',
        userType:''
       
      });
      useEffect(() => {
        const fetchUserData = async () => {
          const token = Cookies.get('accessToken');
          if (!token) {
            console.error('Access token not found');
            return;
          }
    
          try {
            const userId = getUserIdFromToken(token);
            if (!userId) {
              console.error('Failed to extract user ID from access token.');
              return;
            }
    
            console.log('User ID:', userId);
    
            const response = await axios.get(`https://tailors-api.azurewebsites.net/api/Users/${userId}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
    
            const user = response.data; // Assuming single user data is returned
            setFormData({
              id: user.id,
              userType :user.userType
            });
          } catch (error) {
            console.error('Failed to fetch user data:', error);
          }
        };
    
        fetchUserData();
      }, []); // Fetch data only once on component mount
      const getUserIdFromToken = (token) => {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userid; // Assuming 'userid' is the custom claim in the token payload
          return userId;
        } catch (error) {
          console.error('Error decoding access token:', error);
          return null;
        }
      };
  
    return (
      <div>
           <ToastContainer />
        <div className="vertical-overlay"></div>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="h-100">
                    <div className="row">
                      <div className="col-xl-3 col-md-6">
                        <div className="card card-animate">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                  Total Earnings
                                </p>
                              </div>
                              <div className="flex-shrink-0">
                                <h5 className="text-success fs-14 mb-0">
                                  <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                                  +16.24 %
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                  $<span className="counter-value" data-target="559.25">
                                    0
                                  </span>
                                  k
                                </h4>
                                <a href="#" className="text-decoration-underline">
                                  View net earnings
                                </a>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                            
  
  <span className="avatar-title bg-success rounded fs-3">
    <i className="bx bx-dollar-circle"></i>
  </span>
  </div>
  </div>
  </div>
  </div>
  </div>
  <div className="col-xl-3 col-md-6">
  <div className="card card-animate">
  <div className="card-body">
  <div className="d-flex align-items-center">
  <div className="flex-grow-1 overflow-hidden">
  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
    Orders
  </p>
  </div>
  <div className="flex-shrink-0">
  <h5 className="text-danger fs-14 mb-0">
    <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
    -3.57 %
  </h5>
  </div>
  </div>
  <div className="d-flex align-items-end justify-content-between mt-4">
  <div>
  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
    <span className="counter-value" data-target="36894">
      0
    </span>
  </h4>
  <a href="#" className="text-decoration-underline">
    View all orders
  </a>
  </div>
  <div className="avatar-sm flex-shrink-0">
  <span className="avatar-title bg-info rounded fs-3">
    <i className="bx bx-shopping-bag"></i>
  </span>
  </div>
  </div>
  </div>
  </div>
  </div>
  <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Tailors
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className="text-success fs-14 mb-0">
                    <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                    +29.08 %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">{userCount}</span> M
                  </h4>
                  <a href="" className="text-decoration-underline"onClick={()=>{Navigate('/Cistomer')}}>
                    See details
                  </a>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-warning rounded fs-3">
                    <i className="bx bx-user-circle"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
     
  <div className="col-xl-3 col-md-6">
  <div className="card card-animate">
  <div className="card-body">
  <div className="d-flex align-items-center">
  <div className="flex-grow-1 overflow-hidden">
  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
    My Balance
  </p>
  </div>
  <div className="flex-shrink-0">
  <h5 className="text-muted fs-14 mb-0">
    +0.00 %
  </h5>
  </div>
  </div>
  <div className="d-flex align-items-end justify-content-between mt-4">
  <div>
  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
    $
    <span className="counter-value" data-target="165.89">
      0
    </span>
    k
  </h4>
  <a href="#" className="text-decoration-underline">
    Withdraw money
  </a>
  </div>
  <div className="avatar-sm flex-shrink-0">
  <span className="avatar-title bg-danger rounded fs-3">
    <i className="bx bx-wallet"></i>
  </span>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  <div className="row">
  <div className="col-lg-12">
  <div className="card">
  <div className="card-header">
  <h4 className="card-title mb-0">Service</h4>
  </div>
  <div className="card">
  <div className="card-body">
    <div className="row g-4 mb-3">
      <div className="col-sm-auto">
        <div>
          <div className="container">
          { formData.userType === 'client' &&  <button
              type="button"
              className="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#addUserModal"
            >
              Add Service
            </button>}
            {/* Add User Modal */}
            <div
              className="modal fade"
              id="addUserModal"
              tabIndex="-1"
              aria-labelledby="addUserModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addUserModalLabel">
                     {editingUser ?'Edit service ' : 'Add service'}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      onClick={closeEditModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form>
                    
                      <div className="mb-3">
                        <label
                          htmlFor="email"
                          className="form-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="name"
                          value={newServiceData.name}
                          onChange={handleNameChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="phone"
                          className="form-label"
                        >
                         Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="price"
                          value={newServiceData.price}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="address"
                          className="form-label"
                        >
                         Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          name="description"
                          value={newServiceData.description}
                          onChange={handleInputChange}
                        />
                      </div>
                   
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={closeEditModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={
                        editingUser
                          ? handleEditUser
                          : handleAddUser
                      }
                    >
                      {editingUser ? 'Save Changes' : 'Add Service'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm">
        <div className="d-flex justify-content-sm-end">
          <div className="search-box ms-2">
            <input
              type="text"
              className="form-control search"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <i className="ri-search-line search-icon"></i>
          </div>
        </div>
      </div>
    </div>
    <div className="table-responsive table-card mt-3 mb-1">
      <table className="table align-middle table-nowrap">
        <thead className="text-muted table-light">
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Price</th>
            <th>Description</th>
           {formData.userType ==="client" && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {filteredService.map((service, index) => (
            <tr key={index}>
              <td className='fw-medium link-primary'>{service.code}</td>
              <td>{service.name}</td>
              <td className='text-success'>{`$${service.price || 'N/A'}`}</td>
              <td>{service.description || 'N/A'}</td>
              {formData.userType ==="client" &&  <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addUserModal"
                  onClick={() => openEditModal(service)}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-danger ms-2"
                  onClick={() => handleDeleteUser((service.id))} 
                >
                  Delete
  
  
  
  </button>
  </td>}
  </tr>
  ))}
  </tbody>
  </table>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  )}
  
  export default Service;














