import React from 'react'
import Header from '../Components/Header'
import Service from '../Components/Service'

export default function Servicespage() {
  return (
    <div><div id="layout-wrapper">
        <Header/>
        <Service/>
        </div>
    </div>
  )
}
