
import logo from '../Images/logo-light.png';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';
import { toast ,ToastContainer} from 'react-toastify';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleForgetPassword = async () => {
        try { 
            setLoading(true);
            const response = await axios.post('https://tailors-api.azurewebsites.net/Users/Forget', { Email: email });

            if (response.status === 200) {
                const message = response.data;
                setErrorMessage(message);
                toast.success(message, {
                    className: 'toast-success',
                    position: 'top-right',
                    autoClose: 5000
                });
            }
        } catch (error) {
            console.error('Password reset email failed:', error);

            if (error.response && error.response.data) {
                const errorMessage = error.response.data;
                setErrorMessage(errorMessage);
                toast.error(errorMessage, {
                    className: 'toast-error',
                    position: 'top-right',
                    autoClose: 5000
                });
            } else {
                setErrorMessage('Failed to send password reset email. Please try again later.');
                toast.error('Failed to send password reset email. Please try again later.', {
                    className: 'toast-error',
                    position: 'top-right',
                    autoClose: 5000
                });
            }
        } finally {
            setLoading(false);
        }
    };
    const navigate = useNavigate();
    const SubmitForget=() =>{
                
        navigate('/login',{replace:true})
    }
  return (
  <div>
    <ToastContainer/>
    <div className="auth-page-wrapper pt-5">
    {loading && (
        <div id="preloader">
          <div id="status">
            <div className="spinner-border text-primary avatar-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
<div className="auth-one-bg-position auth-one-bg" id="auth-particles" style={{backgroundColor:'45cb85'}}>
    <div className="bg-overlay" ></div>

   
</div>

<div className="auth-page-content">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="text-center mt-sm-5 mb-4 text-white-50" >
                    <div>
                        <a href="index.html" className="d-inline-block auth-logo">
                            <img src={logo} alt="" height="20"/>
                        </a>
                    </div>
                    <p className="mt-3 fs-15 fw-medium">Tailor Solution</p>
                </div>
            </div>
        </div>
       

        <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4">

                    <div className="card-body p-4">
                        <div className="text-center mt-2">
                            <h5 className="text-primary">Forgot Password?</h5>
                            <p className="text-muted">Reset password with Tailor Solution</p>

                            <lord-icon src="https://cdn.lordicon.com/rhvddzym.json" trigger="loop" colors="primary:#0ab39c" className="avatar-xl">
                            </lord-icon>

                        </div>

                        <div className="alert border-0 alert-warning text-center mb-2 mx-2" role="alert">
                            Enter your email and instructions will be sent to you!
                        </div>
                        <div className="p-2">
                            <form  onSubmit={(e) => { e.preventDefault(); handleForgetPassword(); }}>
                                <div className="mb-4">
                                    <label className="form-label">Email</label>
                                    <input  type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required className="form-control" id="email" placeholder="Enter Email"/>
                                </div>

                                <div className="text-center mt-4">
                                    <button className="btn btn-success w-100" type="submit"  disabled={loading}>
                                    {loading ? 'Sending...' : 'Send Reset Email'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                   
                </div>
            

                <div className="mt-4 text-center">
                    <p className="mb-0">Wait, I remember my password... <a href="" className="fw-semibold text-primary text-decoration-underline" onClick={()=>SubmitForget()}> Click here </a> </p>
                </div>

            </div>
        </div>
      
    </div>
  
</div>

<footer className="footer">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="text-center">
                <p className="mb-0 text-muted">&copy; Copyright to Tailor Solution 2024</p>
                </div>
            </div>
        </div>
    </div>
</footer>

</div>
  </div>
);
};

export default ForgetPassword;