import React, { useEffect,useState } from 'react';
import Cookies from 'js-cookie';
import logo from '../Images/logo-light.png';
import { useNavigate ,useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGoogleLogin, useGoogleOAuth } from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";


function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showResendEmailButton, setShowResendEmailButton] = useState(false);
  const { isSignedIn, user } = useGoogleOAuth();

  // const login = useGoogleLogin({
  //     onSuccess: (tokenResponse) => {
  //         if (tokenResponse && tokenResponse.access_token) {
  //             navigate('/Dash', { state: { user: user } })
  //           const token = tokenResponse.access_token;
    
  //           // Decode the JWT token to access user information
  //           const decodedToken = jwtDecode(token);
  //           console.log('Decoded Token:', decodedToken);
    
  //           // Navigate to Dashboard page with user data in state
  //          ;
  //         } else {
  //           console.error('Access token not found in token response');
  //         }
  //       },
  //       onError: (error) => {
  //         console.error(error);
  //         setErrorMessage('Google login failed');
  //       },
  // });
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const [showPassword, setShowPassword] = useState(false);
    
      const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
      };
  const SubmitForget = () => {
    navigate('/signup', { replace: true });
  };

  const Submit = () => {
    navigate('/forgetpassword', { replace: true });
  };
  useEffect(() => {
    const token = Cookies.get('accessToken');
    if (token) {
      console.log('Token found in cookies:', token);
      window.runtime = window.runtime || {};
      window.runtime.token = token;
    }

    // Show success message if present in state
    if (location.state && location.state.successMessage) {
      toast.success(location.state.successMessage, {
        className: 'toast-success',
        position: 'top-right',
        autoClose: 5000
      });
    }
  }, [location.state]);
const handleResendEmail = async () => {
  try {
    setLoading(true);
    const response = await axios.post('https://tailors-api.azurewebsites.net/api/Users/ResendEmailConfirmation', { Email });

    if (response.status === 200) {
      const message = response.data;
      setErrorMessage(message);
      toast.success(message, {
        className: 'toast-success',
        position: 'top-right',
        autoClose: 5000
      });
      setShowResendEmailButton(false);
    }
  } catch (error) {
    console.error('Resend email failed:', error);

    if (error.response && error.response.data) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      toast.error(errorMessage, {
        className: 'toast-error',
        position: 'top-right',
        autoClose: 5000
      });
    } else {
      setErrorMessage('Failed to resend confirmation email. Please try again later.');
      toast.error('Failed to resend confirmation email. Please try again later.', {
        className: 'toast-error',
        position: 'top-right',
        autoClose: 5000
      });
    }
  } finally {
    setLoading(false);
  }
};

const handleSubmit = async () => {
  setLoading(true);
  setShowResendEmailButton(false);
  setErrorMessage('');
  try {
    const response = await axios.post('https://tailors-api.azurewebsites.net/api/Users/login', {
      Email,
      Password
    });

    if (response.status === 200) {
      const { accessToken, expiresIn, redirectUrl } = response.data;
      const expiryTimeInSeconds = parseInt(expiresIn);
      const expiryDate = new Date(Date.now() + expiryTimeInSeconds * 1000);

      // Save the access token in cookies
      Cookies.set('accessToken', accessToken, { expires: expiryDate });
      navigate(redirectUrl, { replace: true });
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      const token = Cookies.get('accessToken');

      if (!token) {
        console.error('Access token not found');
        return;
      }
    } else {
      setErrorMessage('Invalid credentials');
      toast.error('Invalid credentials', {
        className: 'toast-error',
        position: 'top-right',
        autoClose: 5000
      });
    }
  } catch (error) {
    console.error('Login failed:', error);

    if (error.response && error.response.data) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      toast.error(errorMessage, {
        className: 'toast-error',
        position: 'top-right',
        autoClose: 5000
      });

      if (errorMessage === 'Email is not confirmed. Please check your email for confirmation.') {
        setShowResendEmailButton(true);
      }
    } else {
      setErrorMessage('An unexpected error occurred.');
      toast.error('An unexpected error occurred.', {
        className: 'toast-error',
        position: 'top-right',
        autoClose: 5000
      });
    }
  } finally {
    setLoading(false);
  }
};





  return (
<div>
  <ToastContainer/>
      <div className="auth-page-wrapper pt-5">
      {loading && (
        <div id="preloader">
          <div id="status">
            <div className="spinner-border text-primary avatar-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay"></div>
        </div>
        <div className="auth-page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <a href="" className="d-inline-block auth-logo">
                      <img src={logo} alt="" height="120px"  width="120px"/>
                    </a>
                  </div>
               
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">Sign in to continue to Tailor Solution.</p>
                    </div>
                    <div className="p-2 mt-4">
                      <form>
                          <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input
                              type="text"
                              className="form-control"
                              id="Email"
                              value={Email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter username"
                            />
                          </div>
              
                        <div className="mb-3">
                         
                          <label className="form-label" htmlFor="password-input">
                            Password
                          </label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <input
                              type={showPassword ? 'text' : 'password'}
                              className="form-control pe-5 password-input"
                              onPaste={(e) => e.preventDefault()}
                              placeholder="Enter password"
                              id="password-input"
                              value={Password}
                            onChange={(e) => setPassword(e.target.value)}
                            />
                             <button
        className="btn btn-link position-absolute end-0 top-0 text-decoration-none shadow-none text-muted password-addon"
        type="button"
        id="password-addon"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? (
          <i className="ri-eye-close-line align-middle"></i>
        ) : (
          <i className="ri-eye-line align-middle"></i>
        )}
      </button>
                          </div>
                       
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </label>
                          <div className="float-end">
                            <a
                              href=""
                              className="text-muted"
                              onClick={() => Submit()}
                            >
                              Forgot password?
                            </a>
                          </div>
                        </div>
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="button"  disabled={loading}

                            onClick={(e)=>{handleSubmit();e.preventDefault()}}
                          >
                           {loading ? 'Signing In...' : 'Sign In'}

                          </button>
                         
                          {showResendEmailButton && (
                             <div className="mt-4">
                            <button
                              className="btn btn-success w-100"
                              type="button"
                              onClick={handleResendEmail}
                              disabled={loading}
                            >
                              Resend Email Confirmation
                            </button>
                            </div>
                          )}

                        </div>
                        <div className="mt-4 text-center">
                        <div className="signin-other-title">
                                            <h5 className="fs-13 mb-4 title">Don't have an account ?</h5>
                                        </div>
                                        <button
                            className="btn btn-success w-100"
                            type="button"
                            onClick={()=>SubmitForget()}
                          >
                            Sign Up
                          </button>
                        </div>
                   
                            



                                    <div className="mt-4 text-center">
                                        <div className="signin-other-title">
                                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                        </div>
                                        <div className="row">
    <div className="col">
        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light" style={{ marginRight: '8px' }}>
            <i className="ri-facebook-fill fs-16"></i>
        </button>
        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light">
            <i className="ri-google-fill fs-16"></i>
        </button>
    </div>
</div>

                                    </div>
                                </form>
                            </div>
                        </div>
               
                    </div>
                  
{/* 
                    <div className="mt-4 text-center">
                        <p className="mb-0">Don't have an account ? <a href='' className="fw-semibold text-primary text-decoration-underline"  onClick={()=>SubmitForget()}> Signup </a> </p>
                    </div> */}

                </div>
            </div>
        
        </div>
    
    </div>
  
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center">
                        <p className="mb-0 text-muted">&copy; Copyright to Tailor Solution 2024</p>
                        <p className="mb-0 text-muted">
                            {/* <script>document.write(new Date().getFullYear())</script> Velzon. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
</div>
  );
};

export default Login;

