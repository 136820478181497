import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ENG from './resources/en'
import  AR from './resources/ur'
const storedLanguage = localStorage.getItem('i18nextLng');




i18n
  .use(initReactI18next)
  .init({
    resources:{
      ENG,
      AR
    },
    lng:storedLanguage || 'ENG',// Default language
    fallbackLng: 'ENG', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;