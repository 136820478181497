
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Navigate ,useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Customer() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const initialUserData = {
    firstName: '',
    email: '',
    phoneNumber: '',
    address: '',
  };
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [newUserData, setNewUserData] = useState(initialUserData);
  const [tailorUsers, setTailorUsers] = useState([]); // State for tailor users
  const [customerUsers, setCustomerUsers] = useState([]); // State for customer users
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = Cookies.get('accessToken');
        if (!token) {
          console.error('Access token not found');
          return;
        }
        const getUserIdFromToken = (token) => {
          try {
              // Decode the JWT token to get the payload
              const decodedToken = jwtDecode(token);
      
              // Extract the userid from the decoded payload
              const userId = decodedToken.userid; // Assuming 'userid' is the custom claim in the token payload
      
              return userId;
          } catch (error) {
              console.error('Error decoding access token:', error);
              return null;
          }
      };
      const userId = getUserIdFromToken(token);

if (userId) {
    console.log('User ID:', userId);
    // Use the extracted userId in your frontend logic (e.g., to determine user-specific actions)
} else {
    console.error('Failed to extract user ID from access token.');
}
        const response = await axios.get('https://tailors-api.azurewebsites.net/api/Users/Getall', {
          headers: { Authorization: `Bearer ${token}` },
});
        const userData = response.data;
        const tailorUser = userData.filter(user => user.userType === 'tailor'); // Corrected the assignment operator
        const customers = userData.filter(user => user.userType !== 'tailor');

        setUsers(customers);
        setTailorUsers(tailorUser); // Set tailor users
        setCustomerUsers(customers); // Set customer users
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);



  useEffect(() => {
    if (!users || !Array.isArray(users)) {
      setFilteredUsers([]);
      return;
    }
  
    const filtered = users.filter((user) => {
      const lowerSearchQuery = searchQuery.toLowerCase();
      // Check if the user's email or name (firstName or userName) matches the search query
      const matchesEmail = user.email.toLowerCase().includes(lowerSearchQuery);
      const matchesName = user.firstName.toLowerCase().includes(lowerSearchQuery); // Assuming firstName field is used
  
      return matchesEmail || matchesName;
    });
  
    setFilteredUsers(filtered);
  }, [searchQuery, users]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUserData({ ...newUserData, [name]: value });
  };

  const handleAddUser = () => {
    const newUser = { ...newUserData };
    setUsers([...users, newUser]);
    setNewUserData(initialUserData);
    // Close the modal after saving changes
    const modal = document.getElementById('addUserModal');
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
    modal.classList.remove('show');
    modal.setAttribute('aria-modal', 'false');
    document.body.classList.remove('modal-open');
    modalBackdrop.parentNode.removeChild(modalBackdrop);
  };

  const handleEditUser = async () => {
    if (!editingUser) return;
    const token = Cookies.get('accessToken');
    if (!token) {
      console.error('Access token not found');
      return;
    }
    try {
      const response = await axios.put(`https://tailors-api.azurewebsites.net/api/Users/${(editingUser.id)}`, newUserData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedUsers = users.map((user) => {
        if (user === editingUser) {
          return { ...user, ...newUserData };
        }
        return user;
      });
      setUsers(updatedUsers);
      setEditingUser(null);
      setNewUserData(initialUserData);
      // Close the modal after saving changes
      const modal = document.getElementById('addUserModal');
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      modal.classList.remove('show');
      modal.setAttribute('aria-modal', 'false');
      document.body.classList.remove('modal-open');
      modalBackdrop.parentNode.removeChild(modalBackdrop);
      toast.success('Edit Customer successfully')
    } catch (error) {
        console.error('Error editing user:', error);
        toast.error('Edit Customer Fail')
        // toast.error(errorMessage, {
        //   className: 'toast-error',
        //   position: 'top-right',
        //   autoClose: 5000
        // });
    }
  };
  
  

  const handleDeleteUser = async (userId) => {
    try {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('Access token not found');
        return;
      }
      await axios.delete(`https://tailors-api.azurewebsites.net/api/Users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const updatedUsers = users.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
      toast.success('Delete Customer successfully')
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Delete Customer Failed')
    }
  };

  const openEditModal = (user) => {
    setEditingUser(user);
    setNewUserData(user);
  };

  const closeEditModal = () => {
    setEditingUser(null);
    setNewUserData(initialUserData);
  }
  const openCustomerDetails = (userId) => {
    navigate(`/customers/${userId}`);
  };
  return (
    <div>
    <ToastContainer/>
      <div className="vertical-overlay"></div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="h-100">
                  <div className="row">
                    <div className="col-xl-3 col-md-6">
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                Total Earnings
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <h5 className="text-success fs-14 mb-0">
                                <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                                +16.24 %
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                $<span className="counter-value" data-target="559.25">
                                  0
                                </span>
                                k
                              </h4>
                              <a href="#" className="text-decoration-underline">
                                View net earnings
                              </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                          

<span className="avatar-title bg-success rounded fs-3">
  <i className="bx bx-dollar-circle"></i>
</span>
</div>
</div>
</div>
</div>
</div>
<div className="col-xl-3 col-md-6">
<div className="card card-animate">
<div className="card-body">
<div className="d-flex align-items-center">
<div className="flex-grow-1 overflow-hidden">
<p className="text-uppercase fw-medium text-muted text-truncate mb-0">
  Orders
</p>
</div>
<div className="flex-shrink-0">
<h5 className="text-danger fs-14 mb-0">
  <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
  -3.57 %
</h5>
</div>
</div>
<div className="d-flex align-items-end justify-content-between mt-4">
<div>
<h4 className="fs-22 fw-semibold ff-secondary mb-4">
  <span className="counter-value" data-target="36894">
    0
  </span>
</h4>
<a href="#" className="text-decoration-underline">
  View all orders
</a>
</div>
<div className="avatar-sm flex-shrink-0">
<span className="avatar-title bg-info rounded fs-3">
  <i className="bx bx-shopping-bag"></i>
</span>
</div>
</div>
</div>
</div>
</div>
<div className="col-xl-3 col-md-6">
        <div className="card card-animate">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Tailor
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className="text-success fs-14 mb-0">
                  <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                  +29.08 %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value">{tailorUsers.length}</span> 
                </h4>
                <a href="" className="text-decoration-underline"onClick={()=>{Navigate('/tailor')}}>
                  See details
                </a>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className="avatar-title bg-warning rounded fs-3">
                  <i className="bx bx-user-circle"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
   
<div className="col-xl-3 col-md-6">
<div className="card card-animate">
<div className="card-body">
<div className="d-flex align-items-center">
<div className="flex-grow-1 overflow-hidden">
<p className="text-uppercase fw-medium text-muted text-truncate mb-0">
 Total Customers
</p>
</div>
<div className="flex-shrink-0">

</div>
</div>
<div className="d-flex align-items-end justify-content-between mt-4">
<div>
<h4 className="fs-22 fw-semibold ff-secondary mb-4">
  
  <span className="counter-value" data-target="165.89">
    {customerUsers.length}
  </span>
  
</h4>
<a href=" " className="text-decoration-underline" onClick={()=>{Navigate('/Customer')}}>
See More Details
</a>
</div>
<div className="avatar-sm flex-shrink-0">
<span className="avatar-title bg-danger rounded fs-3">
  <i className="bx bx-wallet"></i>
</span>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-lg-12">
<div className="card">
<div className="card-header">
<h4 className="card-title mb-0">Customer</h4>
</div>
<div className="card">
<div className="card-body">
  <div className="row g-4 mb-3">
    <div className="col-sm-auto">
      <div>
        <div className="container">
          {/* <button
            type="button"
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#addUserModal"
          >
            Add Customer
          </button> */}
          {/* Add User Modal */}
          <div
            className="modal fade"
            id="addUserModal"
            tabIndex="-1"
            aria-labelledby="addUserModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addUserModalLabel">
                    Add Customer
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={closeEditModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="name"
                        className="form-label"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="firstName"
                        value={newUserData.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={newUserData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="phone"
                        className="form-label"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phoneNumber"
                        value={newUserData.phoneNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="address"
                        className="form-label"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={newUserData.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={closeEditModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      editingUser
                        ? handleEditUser
                        : handleAddUser
                    }
                  >
                    {editingUser ? 'Save Changes' : 'Add Customer'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-sm">
      <div className="d-flex justify-content-sm-end">
        <div className="search-box ms-2">
          <input
            type="text"
            className="form-control search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <i className="ri-search-line search-icon"></i>
        </div>
      </div>
    </div>
  </div>
  <div className="table-responsive table-card mt-3 mb-1">
    <table className="table align-middle table-nowrap">
      <thead className="text-muted table-light">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {filteredUsers.map((user, index) => (
          <tr key={index}>
            <td onClick={() => openCustomerDetails(user.id)}><a href=''>{user.firstName}</a> </td>
            <td>{user.email}</td>
            <td>{user.phoneNumber || 'N/A'}</td>
            <td>{user.address || 'N/A'}</td>
            <td>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addUserModal"
                onClick={() => openEditModal(user)}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={() => handleDeleteUser((user.id))} 
              >
                Delete



</button>
</td>
</tr>
))}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Customer;

