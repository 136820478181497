
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Orders() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [tailorUsers, setTailorUsers] = useState([]);
  const [customerUsers, setCustomerUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [userType, setUserType] = useState('');
  const [newUserData, setNewUserData] = useState({
    name: '',
    description: '',
    assignedTo: '',
    price: '',
    quantity: '',
    delieverDate: '',
    status: '',
  });
  const [editingUser, setEditingUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const token = Cookies.get('accessToken');

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userid; // Adjust based on your token structure
    } catch (error) {
      console.error('Error decoding access token:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!token) {
        console.error('Access token not found');
        return;
      }
  
      try {
        const userId = getUserIdFromToken(token);
        if (!userId) {
          console.error('Failed to extract user ID from access token.');
          return;
        }

  
        const [servicesResponse, userResponse, ordersResponse] = await Promise.all([
          axios.get('https://tailors-api.azurewebsites.net/api/Users/api/services', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`https://tailors-api.azurewebsites.net/api/Users/${userId}`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getorder', { headers: { Authorization: `Bearer ${token}` } }),
        ]);
  
        // Filter the services data if necessary
        const filteredServices = servicesResponse.data.map(service => ({
          id: service.id,
          name: service.name,
          price: service.price,
        }));
  
        setServices(filteredServices);
        setUserType(userResponse.data.userType);
        setOrders(ordersResponse.data);
        setFilteredOrders(ordersResponse.data);
  
        // Fetch tailor and customer users separately to minimize fetched data
        const [TailorUsersResponse ,customerUsersResponse]= await Promise.all([
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getall', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getall', { headers: { Authorization: `Bearer ${token}` } }),
        ]);
  
        setTailorUsers(TailorUsersResponse.data.filter(user => user.userType === 'tailor'));
        setCustomerUsers(customerUsersResponse.data.filter(user => user.userType === 'customer'));
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };
  
    fetchInitialData();
  }, [token]);
  


  useEffect(() => {
    const filtered = orders.filter((order) =>
      order.name && order.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOrders(filtered);
  }, [searchQuery, orders]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleServiceChange = (e) => {
    const selectedServiceId = e.target.value;
    const selectedService = services.find((service) => service.id === selectedServiceId);
    if (selectedService) {
      setNewUserData((prevData) => ({
        ...prevData,
        description: selectedService.name,
        price: selectedService.price,
      }));
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      if (!token) {
        console.error('Access token not found');
        return;
      }
      await axios.delete(`https://tailors-api.azurewebsites.net/deleteOrder/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== userId));
      toast.success('Order deleted successfully');
    } catch (error) {
      console.error('Error deleting order:', error);
      toast.error('Error deleting order');
    }
  };

  const handleAddUser = async () => {
    try {
      if (!token) {
        console.error('Access token not found');
        return;
      }
      const response = await axios.post('https://tailors-api.azurewebsites.net/addOrder', newUserData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders((prevOrders) => [...prevOrders, response.data]);
      setNewUserData({
        name: '',
        description: '',
        assignedTo: '',
        price: '',
        quantity: '',
        delieverDate: '',
        status: '',
      });
      // Close the modal after saving changes
      const modal = document.getElementById('addUserModal');
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      modal.classList.remove('show');
      modal.setAttribute('aria-modal', 'false');
      document.body.classList.remove('modal-open');
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    } catch (error) {
      console.error('Error adding order:', error);
      toast.error('Error adding order');
    }
  };

  const handleEditUser = async () => {
    if (!editingUser) return;
    try {
      if (!token) {
        console.error('Access token not found');
        return;
      }
      await axios.put(`https://tailors-api.azurewebsites.net/updateOrder/${editingUser.id}`, newUserData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders((prevOrders) =>
        prevOrders.map((order) => (order.id === editingUser.id ? { ...order, ...newUserData } : order))
      );
      setEditingUser(null);
      setNewUserData({
        name: '',
        description: '',
        assignedTo: '',
        price: '',
        quantity: '',
        delieverDate: '',
        status: '',
      });
      // Close the modal after saving changes
      const modal = document.getElementById('addUserModal');
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      modal.classList.remove('show');
      modal.setAttribute('aria-modal', 'false');
      document.body.classList.remove('modal-open');
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    } catch (error) {
      console.error('Error editing order:', error);
      toast.error('Error editing order');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'not-started':
        return 'badge bg-secondary-subtle text-secondary';
      case 'Pending':
        return 'badge bg-warning-subtle text-warning';
      case 'Complete':
        return 'badge bg-success-subtle text-success';
      case 'Rejected':
        return 'badge bg-danger-subtle text-danger';
      default:
        return '';
    }
  };

  const calculateTotalPrice = (price, quantity) => {
    const totalPrice = price * quantity;
    return isNaN(totalPrice) ? 'N/A' : totalPrice;
  };

  const openEditModal = (user) => {
    setEditingUser(user);
    setNewUserData(user);
  };

  const closeEditModal = () => {
    setEditingUser(null);
    setNewUserData({
      name: '',
      description: '',
      assignedTo: '',
      price: '',
      quantity: '',
      delieverDate: '',
      status: '',
    });
  };
  const getOrderIdDisplay = (id) => {
    // Assuming the ID is a GUID, you can slice the first 3 characters for display
    return `#${id.slice(0, 6)}`;
  };
//   const createorder=() =>{
                
//     navigate('/NewOrder',{replace:true})
// }

  return (
    <div>
      <ToastContainer/>
      <div className="vertical-overlay"></div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="h-100">
                  <div className="row">
                    <div className="col-xl-3 col-md-6">
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                Total Earnings
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <h5 className="text-success fs-14 mb-0">
                                <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                                +16.24 %
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                $<span className="counter-value" data-target="559.25">
                                  0
                                </span>
                                k
                              </h4>
                              <a href="#" className="text-decoration-underline">
                                View net earnings
                              </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                          

<span className="avatar-title bg-success rounded fs-3">
  <i className="bx bx-dollar-circle"></i>
</span>
</div>
</div>
</div>
</div>
</div>
<div className="col-xl-3 col-md-6">
        <div className="card card-animate">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Orders
                </p>
              </div>
              <div className="flex-shrink-0"></div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="36894">
                    {filteredOrders.length}
                  </span>
                </h4>
                <a href="" className="text-decoration-underline" onClick={() => navigate('/Order')}>
                  View all orders
                </a>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className="avatar-title bg-info rounded fs-3">
                  <i className="bx bx-shopping-bag"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

<div className="col-xl-3 col-md-6">
        <div className="card card-animate">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Total Tailors
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className="text-success fs-14 mb-0">
                  <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                  +29.08 %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value"></span> {tailorUsers.length}
                </h4>
                <a href="" className="text-decoration-underline" onClick={() => navigate('/tailor')}>
                  See details
                </a>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className="avatar-title bg-warning rounded fs-3">
                  <i className="bx bx-user-circle"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
   
<div className="col-xl-3 col-md-6">
<div className="card card-animate">
<div className="card-body">
<div className="d-flex align-items-center">
<div className="flex-grow-1 overflow-hidden">
<p className="text-uppercase fw-medium text-muted text-truncate mb-0">
 Total Customers
</p>
</div>
<div className="flex-shrink-0">
{/* <h5 className="text-muted fs-14 mb-0">
  +0.00 %
</h5> */}
</div>
</div>
<div className="d-flex align-items-end justify-content-between mt-4">
<div>
<h4 className="fs-22 fw-semibold ff-secondary mb-4">
  
  <span className="counter-value" data-target="165.89">
    {customerUsers.length}
  </span>
  
</h4>
<a href="" className="text-decoration-underline" onClick={() => navigate('/Customer')}>
                  See details
                </a>
</div>
<div className="avatar-sm flex-shrink-0">
<span className="avatar-title bg-danger rounded fs-3">
  <i className="bx bx-wallet"></i>
</span>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-lg-12">
<div className="card">
<div className="card-header">
<h4 className="card-title mb-0">Order</h4>
</div>
<div className="card">
<div className="card-body">
  <div className="row g-4 mb-3">
    <div className="col-sm-auto">
      <div>
        <div className="container">
          {userType === 'client' &&<button
            type="button"
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#addUserModal"
          >
            Add Order
          </button>}
         
          {/* Add User Modal */}
          <div
            className="modal fade"
            id="addUserModal"
            tabIndex="-1"
            aria-labelledby="addUserModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addUserModalLabel">
                    {editingUser? 'Edit order' :'Add Order'}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={closeEditModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                   {userType !='tailor' && <div> <div className="mb-3">
                      <label
                        htmlFor="name"
                        className="form-label"
                      >
                        Name
                      </label>
                      <select id="tailorSelect"     name='name' className="form-select"  value={newUserData.name} onChange={handleInputChange} >
        <option value="">Select a Customer</option>
        {customerUsers.map((user) => (
          <option key={user.id} value={user.firstName}>{user.firstName}</option>
        ))}
      </select>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                        Product
                      </label>
                      <select
            id="serviceSelect"
            name="description"
            className="form-select"
            value={newUserData.description}
            onChange={handleInputChange}
          >
            <option value="">Select a product</option>
            {services.map((service) => (
              <option key={service.id} value={service.name}>{service.name}</option>
            ))}
          </select>
                    </div>
               
                    <div className="mb-3">
                      <label
                        htmlFor="address"
                        className="form-label"
                      >
                        AssignedTo
                      </label>
                     
      <select id="tailorSelect"     name='assignedTo' className="form-select"  value={newUserData.assignedTo} onChange={handleInputChange}>
        <option value="">Select a tailor</option>
        {tailorUsers.map((user) => (
          <option key={user.id} value={user.firstName}>{user.firstName}</option>
        ))}
      </select>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                        Price
                      </label>
                      <input
            type="text"
            className="form-control"
            id="price"
            name="price"
            value={newUserData.price}
            readOnly
          />
                    </div>
                    <div className="mb-3">
          <label htmlFor="quantity" className="form-label">
            Quantity
          </label>
          <input
            type="number"
            className="form-control"
            id="quantity"
            name="quantity"
            value={newUserData.quantity}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
                        <label
                          htmlFor="name"
                          className="form-label"
                        >
                          Delivery Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="delieverDate"
                          value={newUserData.delieverDate}
                          onChange={handleInputChange}
                        /></div> </div> }
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                        Status
                      </label>
                      <select
                        type="text"
                        className="form-control"
                        id="email"
                        name="status"
                        value={newUserData.status}
                        onChange={handleInputChange}
                      > 
                       <option value="">Select the status</option>
                        <option value="Pending">Pending</option>
                        <option value="not-started">Not Started</option>
                        <option value="Rejected">Rejected</option>
                      <option value="Complete">Complete</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={closeEditModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      editingUser
                        ? handleEditUser
                        : handleAddUser
                    }
                  >
                    {editingUser ? 'Save Changes' : 'Add Order'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-sm">
      <div className="d-flex justify-content-sm-end">
        <div className="search-box ms-2">
          <input
            type="text"
            className="form-control search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <i className="ri-search-line search-icon"></i>
        </div>
      </div>
    </div>
  </div>
  <div className="table-responsive table-card mt-3 mb-1">
    <table className="table align-middle table-nowrap">
      <thead className="text-muted table-light">
        <tr>
        <th>Order ID</th>
          <th>Name</th>
          <th>Product</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Assigned To</th>
          <th>Delivery Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
          
           { filteredOrders.map((order, index) => (
              <tr key={index}>
                <td className='fw-medium link-primary'>{getOrderIdDisplay(order.id)}</td>
                <td>{order.name}</td>
                <td>{order.description || 'N/A'}</td>
                <td > <span className='text-success'>{`$${calculateTotalPrice(order.price , order.quantity)}`}</span></td>
                <td><span className={getStatusClass(order.status)}>{order.status || 'N/A'}</span></td>
                <td>{order.assignedTo || 'N/A'}</td>
                <td>{formatDate(order.delieverDate) || 'N/A'}</td>
               <td>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#addUserModal"
                    onClick={() => openEditModal(order)}
                  >
                    Edit
                  </button>
                  {userType !== 'tailor' && <button
                    type="button"
                    className="btn btn-danger ms-2"
                    onClick={() => handleDeleteUser(order.id)}
                  >
                    Delete
                  </button>}
                </td>
              </tr>
      ))}
      </tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Orders;


