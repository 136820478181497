import React ,{useState ,useEffect} from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from 'jwt-decode';
export default function Profile( ) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      id: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      UserType: '',
      city: '',
      country: '',
      ZipCode: '',
      address: '',
      joinDatetime:''
    });
  
    useEffect(() => {
      const fetchUserData = async () => {
        const token = Cookies.get('accessToken');
        if (!token) {
          console.error('Access token not found');
          return;
        }
  
        try {
          const userId = getUserIdFromToken(token);
          if (!userId) {
            console.error('Failed to extract user ID from access token.');
            return;
          }
  
          console.log('User ID:', userId);
  
          const response = await axios.get(`https://tailors-api.azurewebsites.net/api/Users/${userId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
  
          const user = response.data; // Assuming single user data is returned
          setFormData({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            email: user.email,
            UserType: user.userType,
            city: user.city,
            country: user.country,
            ZipCode: user.ZipCode,
            address: user.address,
            joinDatetime : user.joinDatetime
          });
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      };
  
      fetchUserData();
    }, []); // Fetch data only once on component mount
  
    const getUserIdFromToken = (token) => {
      try {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userid; // Assuming 'userid' is the custom claim in the token payload
        return userId;
      } catch (error) {
        console.error('Error decoding access token:', error);
        return null;
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Formatting to YYYY-MM-DD
      };
      
    const handleSubmit = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('Access token not found');
        return;
      }
  
      try {
        const response = await axios.put(`https://tailors-api.azurewebsites.net/api/Users/${formData.id}`, formData, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        if (response.status === 200) {
          console.log('Profile updated successfully');

          navigate('/Dash', { replace: true, state: { successMessage: 'User Profile updated successfully.' } });
        } else {
          console.error('Profile update failed:', response.data);
          toast.error('Profile update failed')
        }
      } catch (error) {
        console.error('Profile update failed:', error);
        toast.error('Profile update failed')
      }
    };
    
  return (
    <div>    
          <ToastContainer />
         <div class="main-content">

    <div class="page-content">
        <div class="container-fluid">

            

            <div class="row">
               
            
                <div class="h-100">
                <div class="card mt-n5">
                        <div class="card-body p-4">
                            <div class="text-center">
                                <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                                    <img src="assets/images/users/avatar-1.jpg" class="rounded-circle avatar-xl img-thumbnail user-profile-image  shadow" alt="user-profile-image"/>
                                    <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                                        <input id="profile-img-file-input" type="file" class="profile-img-file-input"/>
                                        <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                                            <span class="avatar-title rounded-circle bg-light text-body shadow">
                                                <i class="ri-camera-fill"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <h5 class="fs-16 mb-1">{formData.firstName} {formData.lastName}</h5>
                                
                            </div>
                        </div>
                    </div>
                    <div class="card mt-xxl-n5">
                        <div class="card-header">
                            <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                        <i class="fas fa-home"></i> Personal Details
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                        <i class="far fa-user"></i> Change Password
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#experience" role="tab">
                                        <i class="far fa-envelope"></i> Experience
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body p-4">
                            <div class="tab-content">
                                <div class="tab-pane active" id="personalDetails" role="tabpanel">
                                    <form action="javascript:void(0);">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="firstnameInput" class="form-label">First Name</label>
                                                    <input type="text" class="form-control" id="firstnameInput" placeholder="Enter your firstname"  name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}/>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="lastnameInput" class="form-label">Last Name</label>
                                                    <input type="text" class="form-control" id="lastnameInput" placeholder="Enter your lastname"   name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}/>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="phonenumberInput" class="form-label">Phone Number</label>
                                                    <input type="text" class="form-control" id="phonenumberInput" placeholder="Enter your phone number" name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleChange}/>
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="emailInput" class="form-label">Email Address</label>
                                                    <input type="email" class="form-control" id="emailInput" placeholder="Enter your email"  name="Email"
                          value={formData.email}
                        />
                                                </div>
                                            </div>
                                      
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="JoiningdatInput" class="form-label">User Type</label>
                                                
                        <select
                          className="form-select"
                          id="userTypeInput"
                          name="UserType"
                          value={formData.UserType}
                          onChange={handleChange}
                          required
                        >
                          <option value="customer">Customer</option>
                          <option value="client">Admin</option>
                          <option value="tailor">Tailor</option>
                        </select>
                                                </div>
                                            </div>
                                           
                                           
{/*                                        
                                          <div className="col-lg-6">
                                         { formData.UserType ==='tailor'  &&<div class="mb-3">
                                                    <label for="designationInput" class="form-label">Designation</label>
                                                    <input type="text" class="form-control" id="designationInput" placeholder="Designation" name='Designation' value={formData.Designation}
                          onChange={handleChange}/>
                                                </div>}
                                                  </div>                                         */}
                                            <div class="col-lg-12">
                                            { formData.UserType ==='tailor' && <div class="mb-3">
                                                    <label for="websiteInput1" class="form-label">Joining Date</label>
                                                    <input type="text" class="form-control" id="websiteInput1" placeholder="YYYY-MM-DD" name='joinDatetime'  value={formData.joinDatetime}
                          onChange={handleChange} />
                                                </div>}
                                            </div> 
                                       <div class="col-lg-4">
                                                <div class="mb-3">
                                                    <label for="cityInput" class="form-label">City</label>
                                                    <input type="text" class="form-control" id="cityInput" placeholder="City" name="city"
                          value={formData.city}
                          onChange={handleChange} />
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-4">
                                                <div class="mb-3">
                                                    <label for="countryInput" class="form-label">Country</label>
                                                    <input type="text" class="form-control" id="countryInput" placeholder="Country" name="country"
                          value={formData.country}
                          onChange={handleChange}/>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-4">
                                                <div class="mb-3">
                                                    <label for="zipcodeInput" class="form-label">Zip Code</label>
                                                    <input type="text" class="form-control" minlength="5" maxlength="6" id="zipcodeInput" placeholder="Enter zipcode" name="ZipCode"
                          value={formData.ZipCode}
                          onChange={handleChange}/>
                                                </div>
                                            </div>
                                       
                                            <div class="col-lg-12">
                                                <div class="mb-3 pb-2">
                                                    <label for="exampleFormControlTextarea" class="form-label">Address</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea" placeholder="Enter your address" rows="3" name="address"
                          value={formData.address}
                          onChange={handleChange}></textarea>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="hstack gap-2 justify-content-end">
                                                <button type="submit" className="btn btn-primary me-2" onClick={(e)=>{handleSubmit();e.preventDefault()}}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => navigate('/Dash', { replace: true })}>
                          Back to Dashboard
                        </button>
                                                    <button type="button" class="btn btn-soft-success">Cancel</button>
                                                </div>
                                            </div>
                                         
                                        </div>
                                    
                                    </form>
                                </div>
                              
                                <div class="tab-pane" id="changePassword" role="tabpanel">
                                    <form action="javascript:void(0);">
                                        <div class="row g-2">
                                            <div class="col-lg-4">
                                                <div>
                                                    <label for="oldpasswordInput" class="form-label">Old Password*</label>
                                                    <input type="password" class="form-control" id="oldpasswordInput" placeholder="Enter current password"/>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-4">
                                                <div>
                                                    <label for="newpasswordInput" class="form-label">New Password*</label>
                                                    <input type="password" class="form-control" id="newpasswordInput" placeholder="Enter new password"/>
                                                </div>
                                            </div>
                                        
                                            <div class="col-lg-4">
                                                <div>
                                                    <label for="confirmpasswordInput" class="form-label">Confirm Password*</label>
                                                    <input type="password" class="form-control" id="confirmpasswordInput" placeholder="Confirm password"/>
                                                </div>
                                            </div>
                                       
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <a href="javascript:void(0);" class="link-primary text-decoration-underline">Forgot Password ?</a>
                                                </div>
                                            </div>
                                      
                                            <div class="col-lg-12">
                                                <div class="text-end">
                                                    <button type="submit" class="btn btn-success">Change Password</button>
                                                </div>
                                            </div>
                                        
                                        </div>
                                
                                    </form>

                             
                               
                                 
                                </div>

                                <div class="tab-pane" id="experience" role="tabpanel">
                                    <form>
                                        <div id="newlink">
                                            <div id="1">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="mb-3">
                                                            <label for="jobTitle" class="form-label">Job Title</label>
                                                            <input type="text" class="form-control" id="jobTitle" placeholder="Job title" value="Lead Designer / Developer"/>
                                                        </div>
                                                    </div>
                                              
                                                    <div class="col-lg-6">
                                                        <div class="mb-3">
                                                            <label for="companyName" class="form-label">Company Name</label>
                                                            <input type="text" class="form-control" id="companyName" placeholder="Company name" value="Themesbrand"/>
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="col-lg-6">
                                                        <div class="mb-3">
                                                            <label for="experienceYear" class="form-label">Experience Years</label>
                                                            <div class="row">
                                                                <div class="col-lg-5">
                                                                    <select class="form-control" data-choices data-choices-search-false name="experienceYear" id="experienceYear">
                                                                        <option value="">Select years</option>
                                                                        <option value="Choice 1">2001</option>
                                                                        <option value="Choice 2">2002</option>
                                                                        <option value="Choice 3">2003</option>
                                                                        <option value="Choice 4">2004</option>
                                                                        <option value="Choice 5">2005</option>
                                                                        <option value="Choice 6">2006</option>
                                                                        <option value="Choice 7">2007</option>
                                                                        <option value="Choice 8">2008</option>
                                                                        <option value="Choice 9">2009</option>
                                                                        <option value="Choice 10">2010</option>
                                                                        <option value="Choice 11">2011</option>
                                                                        <option value="Choice 12">2012</option>
                                                                        <option value="Choice 13">2013</option>
                                                                        <option value="Choice 14">2014</option>
                                                                        <option value="Choice 15">2015</option>
                                                                        <option value="Choice 16">2016</option>
                                                                        <option value="Choice 17" selected>2017</option>
                                                                        <option value="Choice 18">2018</option>
                                                                        <option value="Choice 19">2019</option>
                                                                        <option value="Choice 20">2020</option>
                                                                        <option value="Choice 21">2021</option>
                                                                        <option value="Choice 22">2022</option>
                                                                    </select>
                                                                </div>
                                                                
                                                                <div class="col-auto align-self-center">
                                                                    to
                                                                </div>
                                                             
                                                                <div class="col-lg-5">
                                                                    <select class="form-control" data-choices data-choices-search-false name="choices-single-default2">
                                                                        <option value="">Select years</option>
                                                                        <option value="Choice 1">2001</option>
                                                                        <option value="Choice 2">2002</option>
                                                                        <option value="Choice 3">2003</option>
                                                                        <option value="Choice 4">2004</option>
                                                                        <option value="Choice 5">2005</option>
                                                                        <option value="Choice 6">2006</option>
                                                                        <option value="Choice 7">2007</option>
                                                                        <option value="Choice 8">2008</option>
                                                                        <option value="Choice 9">2009</option>
                                                                        <option value="Choice 10">2010</option>
                                                                        <option value="Choice 11">2011</option>
                                                                        <option value="Choice 12">2012</option>
                                                                        <option value="Choice 13">2013</option>
                                                                        <option value="Choice 14">2014</option>
                                                                        <option value="Choice 15">2015</option>
                                                                        <option value="Choice 16">2016</option>
                                                                        <option value="Choice 17">2017</option>
                                                                        <option value="Choice 18">2018</option>
                                                                        <option value="Choice 19">2019</option>
                                                                        <option value="Choice 20" selected>2020</option>
                                                                        <option value="Choice 21">2021</option>
                                                                        <option value="Choice 22">2022</option>
                                                                    </select>
                                                                </div>
                                                            
                                                            </div>
                                                         
                                                        </div>
                                                    </div>
                                              
                                                    <div class="col-lg-12">
                                                        <div class="mb-3">
                                                            <label for="jobDescription" class="form-label">Job Description</label>
                                                            <textarea class="form-control" id="jobDescription" rows="3" placeholder="Enter description">You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. </textarea>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="hstack gap-2 justify-content-end">
                                                        <a class="btn btn-success" href="javascript:deleteEl(1)">Delete</a>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                        <div id="newForm" style={{display: "none"}}>

                                        </div>
                                        <div class="col-lg-12">
                                            <div class="hstack gap-2">
                                                <button type="submit" class="btn btn-success">Update</button>
                                                <a href="javascript:new_link()" class="btn btn-primary">Add New</a>
                                            </div>
                                        </div>
                                      
                                    </form>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
             
            </div>
          

        </div>
        
    </div>

    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                  <p className="mb-0 text-muted">&copy; Copyright to Tailor Solution 2024</p>
                </div>
                <div class="col-sm-6">
                  
                </div>
            </div>
        </div>
    </footer>
</div>
</div>
  )
}
