import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function TailorOrder( ) {

    const [services, setServices] = useState([]);
  const [tailorUsers, setTailorUsers] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [customerUsers, setCustomerUsers] = useState([]);
  const [userType, setUserType] = useState('');
  const [orders, setOrders] = useState([]);
  const token = Cookies.get('accessToken');
  const [editingUser, setEditingUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    const filtered = orders.filter((order) =>
      order.name && order.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOrders(filtered);
  }, [searchQuery, orders]);
  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userid; // Adjust based on your token structure
    } catch (error) {
      console.error('Error decoding access token:', error);
      return null;
    }
  };
  const handleEditUser = async () => {
    if (!editingUser) return;
    try {
      if (!token) {
        console.error('Access token not found');
        return;
      }
      await axios.put(`https://tailors-api.azurewebsites.net/updateOrder/${editingUser.id}`, newUserData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders((prevOrders) =>
        prevOrders.map((order) => (order.id === editingUser.id ? { ...order, ...newUserData } : order))
      );
      setEditingUser(null);
      setNewUserData({
        name: '',
        description: '',
        assignedTo: '',
        price: '',
        quantity: '',
        delieverDate: '',
        status: '',
      });
      // Close the modal after saving changes
      const modal = document.getElementById('addUserModal');
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      modal.classList.remove('show');
      modal.setAttribute('aria-modal', 'false');
      document.body.classList.remove('modal-open');
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    } catch (error) {
      console.error('Error editing order:', error);
      toast.error('Error editing order');
    }
  };

  
  const openEditModal = (user) => {
    setEditingUser(user);
    setNewUserData(user);
  };

  const closeEditModal = () => {
    setEditingUser(null);
    setNewUserData({
      name: '',
      description: '',
      assignedTo: '',
      price: '',
      quantity: '',
      delieverDate: '',
      status: '',
    });}
  useEffect(() => {
    const fetchInitialData = async () => {
      if (!token) {
        console.error('Access token not found');
        return;
      }
  
      try {
        const userId = getUserIdFromToken(token);
        if (!userId) {
          console.error('Failed to extract user ID from access token.');
          return;
        }
      
  
        const [servicesResponse, userResponse, ordersResponse] = await Promise.all([
          axios.get('https://tailors-api.azurewebsites.net/api/Users/api/services', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`https://tailors-api.azurewebsites.net/api/Users/${userId}`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getorder', { headers: { Authorization: `Bearer ${token}` } }),
        ]);
  
        // Filter the services data if necessary
        const filteredServices = servicesResponse.data.map(service => ({
          id: service.id,
          name: service.name,
          price: service.price,
        }));
  
        setServices(filteredServices);
        setUserType(userResponse.data.firstName);
        setOrders(ordersResponse.data);
        setFilteredOrders(ordersResponse.data);
  
        // Fetch tailor and customer users separately to minimize fetched data
        const [tailorUsersResponse, customerUsersResponse] = await Promise.all([
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getall', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getall', { headers: { Authorization: `Bearer ${token}` } }),
        ]);
  
        setTailorUsers(tailorUsersResponse.data.filter(user => user.userType === 'tailor'));
        setCustomerUsers(customerUsersResponse.data.filter(user => user.userType === 'customer'));
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };
  
    fetchInitialData();
  }, [token]);
  
    const [newUserData, setNewUserData] = useState({
        name: '',
        description: '',
        assignedTo: '',
        price: '',
        quantity: '',
        delieverDate: '',
        status: '',
      });
      const getOrderIdDisplay = (id) => {
        // Assuming the ID is a GUID, you can slice the first 3 characters for display
        return `#${id.slice(0, 6)}`;
      };
      const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      };
    
      const getStatusClass = (status) => {
        switch (status) {
          case 'not-started':
            return 'badge bg-secondary-subtle text-secondary';
          case 'Pending':
            return 'badge bg-warning-subtle text-warning';
          case 'Complete':
            return 'badge bg-success-subtle text-success';
          case 'Rejected':
            return 'badge bg-danger-subtle text-danger';
          default:
            return '';
        }
      };
    
     
      const handleAddUser = async () => {
        try {
          if (!token) {
            console.error('Access token not found');
            return;
          }
          const response = await axios.post('https://tailors-api.azurewebsites.net/addOrder', newUserData, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setOrders((prevOrders) => [...prevOrders, response.data]);
          setNewUserData({
            name: '',
            description: '',
            assignedTo: '',
            price: '',
            quantity: '',
            delieverDate: '',
            status: '',
          });
        toast.success('Order is created Successfully')
        } catch (error) {
          console.error('Error adding order:', error);
          toast.error('Error adding order');
        }
      };
  

  
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUserData((prevData) => ({ ...prevData, [name]: value }));
      };
   
    

  const calculateTotalPrice = (price, quantity) => {
    const totalPrice = price * quantity;
    return isNaN(totalPrice) ? 'N/A' : totalPrice;
  };
   
    
  return (
    <div>    
          <ToastContainer />
         <div class="main-content">

    <div class="page-content">
        <div class="container-fluid">

            

            <div class="row">
               
            
                <div class="h-100">
                <div class="card mt-n5">
                <div class="card-body p-4"> <div class="text-center">
                                <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                                   <h1> ORDER DETAILS</h1>
                                   
                                </div>
                               
                            </div>
                    </div></div>
                    <div class="card mt-xxl-n5">
                    <div class="card-header">
                            <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                        <i class="fas fa-home"></i> Order Details
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                        <i class="far fa-user"></i>Order Completed
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " data-bs-toggle="tab" href="#orderrejected" role="tab">
                                        <i class="fas fa-home"></i> Order Rejected
                                    </a>
                                </li>
                               
                            </ul>
                        </div>
                        <div class="card-body p-4">
                            <div class="tab-content">
                            <div class="tab-pane active" id="personalDetails" role="tabpanel">
                                <div className="card">

        <div className="container">
        
         
          {/* Add User Modal */}
          <div
            className="modal fade"
            id="addUserModal"
            tabIndex="-1"
            aria-labelledby="addUserModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addUserModalLabel">
                    {editingUser? 'Edit order' :'Add Order'}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={closeEditModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                        Status
                      </label>
                      <select
                        type="text"
                        className="form-control"
                        id="email"
                        name="status"
                        value={newUserData.status}
                        onChange={handleInputChange}
                      > 
                       <option value="">Select the status</option>
                        <option value="Pending">Pending</option>
                        <option value="not-started">Not Started</option>
                        <option value="Rejected">Rejected</option>
                      <option value="Complete">Complete</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={closeEditModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      editingUser
                        ? handleEditUser
                        : handleAddUser
                    }
                  >
                    {editingUser ? 'Save Changes' : 'Add Order'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm">
      <div className="d-flex justify-content-sm-end">
        <div className="search-box ms-2">
          <input
            type="text"
            className="form-control search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <i className="ri-search-line search-icon"></i>
        </div>
      </div>
    </div>
    <div className="table-responsive table-card mt-3 mb-1">
    </div>
  
    <table className="table align-middle table-nowrap">
      <thead className="text-muted table-light">
        <tr>
        <th>Order ID</th>
          <th>Name</th>
          <th>Product</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Assigned To</th>
          <th>Delivery Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
          
           { filteredOrders .filter(order => order.assignedTo === userType).map((order, index) => (
              <tr key={index}>
                <td className='fw-medium link-primary'>{getOrderIdDisplay(order.id)}</td>
                <td>{order.name}</td>
                <td>{order.description || 'N/A'}</td>
                <td > <span className='text-success'>{`$${calculateTotalPrice(order.price , order.quantity)}`}</span></td>
                <td><span className={getStatusClass(order.status)}>{order.status || 'N/A'}</span></td>
                <td>{order.assignedTo || 'N/A'}</td>
                <td>{formatDate(order.delieverDate) || 'N/A'}</td>
               <td>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#addUserModal"
                    onClick={() => openEditModal(order)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
      ))}
      </tbody>
</table>
  </div>
  <div class="tab-pane" id="changePassword" role="tabpanel">
  <div className="col-sm">
      <div className="d-flex justify-content-sm-end">
        <div className="search-box ms-2">
          <input
            type="text"
            className="form-control search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <i className="ri-search-line search-icon"></i>
        </div>
      </div>
    </div>
    <div className="table-responsive table-card mt-3 mb-1">
    </div>
                                <table className="table align-middle table-nowrap">
      <thead className="text-muted table-light">
        <tr>
        <th>Order ID</th>
          <th>Name</th>
          <th>Product</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Assigned To</th>
          <th>Delivery Date</th>
         
        </tr>
      </thead>
      <tbody>
    {filteredOrders
      .filter(order => order.assignedTo === userType && order.status === 'Complete') // Filter orders for the current user
      .map((order, index) => (
        <tr key={index}>
          <td className='fw-medium link-primary'>{getOrderIdDisplay(order.id)}</td>
          <td>{order.name}</td>
          <td>{order.description || 'N/A'}</td>
          <td><span className='text-success'>{`$${calculateTotalPrice(order.price , order.quantity)}`}</span></td>
          <td><span className={getStatusClass(order.status)}>{order.status || 'N/A'}</span></td>
          <td>{order.assignedTo || 'N/A'}</td>
          <td>{formatDate(order.delieverDate) || 'N/A'}</td>
        </tr>
      ))}
  </tbody>
</table>

                                </div>
                                <div class="tab-pane" id="orderrejected" role="tabpanel">
  <div className="col-sm">
      <div className="d-flex justify-content-sm-end">
        <div className="search-box ms-2">
          <input
            type="text"
            className="form-control search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <i className="ri-search-line search-icon"></i>
        </div>
      </div>
    </div>
    <div className="table-responsive table-card mt-3 mb-1">
    </div>
                                <table className="table align-middle table-nowrap">
      <thead className="text-muted table-light">
        <tr>
        <th>Order ID</th>
          <th>Name</th>
          <th>Product</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Assigned To</th>
          <th>Delivery Date</th>
         
        </tr>
      </thead>
      <tbody>
    {filteredOrders
      .filter(order => order.assignedTo === userType && order.status === 'Rejected') // Filter orders for the current user
      .map((order, index) => (
        <tr key={index}>
          <td className='fw-medium link-primary'>{getOrderIdDisplay(order.id)}</td>
          <td>{order.name}</td>
          <td>{order.description || 'N/A'}</td>
          <td><span className='text-success'>{`$${calculateTotalPrice(order.price , order.quantity)}`}</span></td>
          <td><span className={getStatusClass(order.status)}>{order.status || 'N/A'}</span></td>
          <td>{order.assignedTo || 'N/A'}</td>
          <td>{formatDate(order.delieverDate) || 'N/A'}</td>
        </tr>
      ))}
  </tbody>
</table>

                                </div>
                                </div>
                                
                      

                              
                              
                            </div>
                        </div>
                    </div>
                </div>
             
            </div>
          

        </div>
        
    </div>

    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                <p className="mb-0 text-muted">&copy; Copyright to Tailor Solution 2024</p>
                </div>
                <div class="col-sm-6">
                  
                </div>
            </div>
        </div>
    </footer>
</div>

  )
}
