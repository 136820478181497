import React from 'react'
import Header from '../Components/Header'
import CRM from '../Components/CRM'

export default function Crmpage() {
  return (
    <div>
        <div id="layout-wrapper">
            <Header/>
            <CRM/>
        </div>
    </div>
  )
}
