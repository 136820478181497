import React,{useState} from 'react';
import logo from '../Images/logo-light.png';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
function Signup () {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      UserName:'',
        Email: '',
        Password: ''
      });
      const {UserName, Email, Password } = formData;
      const [loading, setLoading] = useState(false);
      const [errorMessages, setErrorMessages] = useState([]);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://tailors-api.azurewebsites.net/api/Users/Register', {
        UserName,
        Email,
        Password
      });

      toast.success(response.data, {
        className: 'toast-success',
        position: 'top-right',
        autoClose: 5000
      });

      navigate('/Login', { replace: true, state: { successMessage: 'User registered successfully. Please check your email for confirmation.' } });
    } catch (error) {
      if (error.response && error.response.data) {
        const errorResponse = error.response.data;

        if (errorResponse.errors) {
          const errorMessageList = Object.values(errorResponse.errors).flat().map(error => error.description);
          errorMessageList.forEach((message) => {
            toast.error(message, {
              className: 'toast-error',
              position: 'top-right',
              autoClose: 5000
            });
          });
        } else {
          toast.error(errorResponse, {
            className: 'toast-error',
            position: 'top-right',
            autoClose: 5000
          });
        }
      } else {
        toast.error('An unexpected error occurred.', {
          className: 'toast-error',
          position: 'top-right',
          autoClose: 5000
        });
      }
    } finally {
      setLoading(false);
    }
  };


        
    const Submit = () => {
        setLoading(true);
        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 2000); // Navigate to login after 2 seconds (simulating loading)
      };
      

        const [showPassword, setShowPassword] = useState(false);
      
        const togglePasswordVisibility = () => {
          setShowPassword((prevShowPassword) => !prevShowPassword);
        };
  return (
<div>
<ToastContainer />
<div className="auth-page-wrapper pt-5">
{loading && (
        <div id="preloader">
          <div id="status">
            <div className="spinner-border text-primary avatar-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div className="bg-overlay"></div>

           
        </div>

       
        <div className="auth-page-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <a href="index.html" className="d-inline-block auth-logo">
                                    <img src={logo} alt="" height="120px"  width="120px"/>
                                </a>
                            </div>
                            <p className="mt-3 fs-15 fw-medium">Tailor Solution</p>
                        </div>
                    </div>
                </div>
              

                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card mt-4">

                            <div className="card-body p-4">
                                <div className="text-center mt-2">
                                    <h5 className="text-primary">Create New Account</h5>
                                   
                                </div>
                                <div className="p-2 mt-4">
                                    <form className="" onSubmit={handleSubmit} >
                                    <div className="mb-3">
                                            <label for="username" className="form-label">Username<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="useremail" placeholder="Enter Username"name="UserName"  value={UserName}
            onChange={handleChange} required/>
                                            <div className="invalid-feedback">
                                                Please enter username
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label for="useremail" className="form-label">Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" id="useremail" placeholder="Enter email address"name="Email"  value={Email}
            onChange={handleChange} required/>
                                            <div className="invalid-feedback">
                                                Please enter email
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label" for="password-input">Password</label>
                                        <div className="position-relative auth-pass-inputgroup">
      <input
        type={showPassword ? 'text' : 'password'}
        className="form-control pe-5 password-input"
        onPaste={(e) => e.preventDefault()}
        placeholder="Enter password"
        id="password-input"
        aria-describedby="passwordInput"
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
        value={Password}
        name="Password"
        onChange={handleChange}
        required
      />
      <button
        className="btn btn-link position-absolute end-0 top-0 text-decoration-none shadow-none text-muted password-addon"
        type="button"
        id="password-addon"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? (
          <i className="ri-eye-close-line align-middle"></i>
        ) : (
          <i className="ri-eye-line align-middle"></i>
        )}
      </button>
       {errorMessages.length > 0 && (
                                                <div className="alert alert-danger" role="alert">
                                                    {errorMessages.map((error, index) => (
                                                        <p key={index}>{error}</p>
                                                    ))}
                                                </div>
                                            )}
      <div className="invalid-feedback">
        Please enter password
      </div>
    </div>
  </div>                          

                                        <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                            <h5 className="fs-13">Password must contain:</h5>
                                            <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 characters</b></p>
                                            <p id="pass-lower" className="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                            <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                            <p id="pass-number" className="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                        </div>

                                        <div className="mt-4">
                                            <button className="btn btn-success w-100" type="submit" 
                             disabled={loading}
                          >
                        {loading ? 'Signing Up...' : 'Sign Up'}</button>
                                        </div>
                                        <div className="mt-4 text-center">
                        <div className="signin-other-title">
                                            <h5 className="fs-13 mb-4 title">Don't have an account ?</h5>
                                        </div>
                                        <button
                            className="btn btn-success w-100"
                            type="button" onClick={Submit}   disabled={loading}>   {loading ? 'Loading...' : 'Signin'}

                          </button>
                        </div>

                                        <div className="mt-4 text-center">
                                            <div className="signin-other-title">
                                                <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>
                                            </div>

                                            <div className="row">
    <div className="col">
        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light" style={{ marginRight: '8px' }}>
            <i className="ri-facebook-fill fs-16"></i>
        </button>
        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light">
            <i className="ri-google-fill fs-16"></i>
        </button>
    </div>
</div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            
                        </div>
            
{/* 
                        <div className="mt-4 text-center">
                            <p className="mb-0">Already have an account ? <a href=" " className="fw-semibold text-primary text-decoration-underline" onClick={()=>Submit()}> Signin </a> </p>
                        </div> */}

                    </div>
                </div>
                
            </div>
         
        </div>
      

    
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                        <p classNameName="mb-0 text-muted">&copy; Copyright to Tailor Solution 2024</p>
                        <p classNameName="mb-0 text-muted">
                                {/* <script>document.write(new Date().getFullYear())</script> Velzon. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    
    </div>
</div>
  );
};

export default Signup;