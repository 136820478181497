import React from 'react'
import Header from '../Components/Header'
import Dash from '../Components/Dashboard'

export default function Dashboardpage() {
  return (
    <div>
        <div id="layout-wrapper">
            <Header/>
            <Dash/>
        </div>
    </div>
  )
}
