import React from 'react'

import Header from '../Components/Header'
import TailorOrder from '../Components/TailorOrder'


export default function  () {
  return (
    <div>  <div id="layout-wrapper">
        <Header/>
       <TailorOrder/>
        </div>
    </div>
  )
}