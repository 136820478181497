import React from 'react'

import Header from '../Components/Header'
import Doorder from '../Components/Doorder'

export default function NewOrder () {
  return (
    <div>  <div id="layout-wrapper">
        <Header/>
        <Doorder/>
        </div>
    </div>
  )
}