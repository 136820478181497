import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwtDecode from 'jwt-decode';

export default function CRM() {
  const { userId } = useParams(); // Fetch customerID from the URL
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const token = Cookies.get('accessToken');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    UserType: '',
    city: '',
    country: '',
    ZipCode: '',
    address: '',
    joinDatetime: ''
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const ordersResponse = await axios.get('https://tailors-api.azurewebsites.net/api/Users/Getorder', { headers: { Authorization: `Bearer ${token}` } });
        setOrders(ordersResponse.data);
        setFilteredOrders(ordersResponse.data);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, [token]);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://tailors-api.azurewebsites.net/api/Users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const user = response.data;
        setFormData({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          email: user.email,
          UserType: user.userType,
          city: user.city,
          country: user.country,
          ZipCode: user.ZipCode,
          address: user.address,
          joinDatetime: user.joinDatetime
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error: for example, show a message to the user or redirect
       
      }
    };
 fetchUserData();
  }, [userId, token, navigate]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Formatting to YYYY-MM-DD
  };

  const handleSubmit = async () => {
    if (!token) {
      console.error('Access token not found');
      return;
    }

    try {
      const response = await axios.put(`https://tailors-api.azurewebsites.net/api/Users/${formData.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        console.log('Profile updated successfully');
        toast.success('Profile updated successfully')
      } else {
        console.error('Profile update failed:', response.data);
        toast.error('Profile update failed');
      }
    } catch (error) {
      console.error('Profile update failed:', error);
      toast.error('Profile update failed');
    }
  };

  const calculateTotalPrice = (price, quantity) => {
    const totalPrice = price * quantity;
    return isNaN(totalPrice) ? 'N/A' : totalPrice;
  };

  const getOrderIdDisplay = (id) => {
    return `#${id.slice(0, 6)}`;
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'not-started':
        return 'badge bg-secondary-subtle text-secondary';
      case 'Pending':
        return 'badge bg-warning-subtle text-warning';
      case 'Complete':
        return 'badge bg-success-subtle text-success';
      case 'Rejected':
        return 'badge bg-danger-subtle text-danger';
      default:
        return '';
    }
  };

 

    
  return (
    <div>    
          <ToastContainer />
         <div class="main-content">

    <div class="page-content">
        <div class="container-fluid">

            

            <div class="row">
               
            
                <div class="h-100">
                <div class="card mt-n5">
                        <div class="card-body p-4">
                            <div class="text-center">
                                {/* <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                                    <img src="assets/images/users/avatar-1.jpg" class="rounded-circle avatar-xl img-thumbnail user-profile-image  shadow" alt="user-profile-image"/>
                                    <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                                        <input id="profile-img-file-input" type="file" class="profile-img-file-input"/>
                                        <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                                            <span class="avatar-title rounded-circle bg-light text-body shadow">
                                                <i class="ri-camera-fill"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div> */}
                               
                                
                            </div>
                        </div>
                    </div>
                    <div class="card mt-xxl-n5">
                        <div class="card-header">
                            <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                        <i class="fas fa-home"></i> Personal Details
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                        <i class="far fa-user"></i> Order Details
                                    </a>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#experience" role="tab">
                                        <i class="far fa-envelope"></i> Experience
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                        <div class="card-body p-4">
                            <div class="tab-content">
                                <div class="tab-pane active" id="personalDetails" role="tabpanel">
                                    <form action="javascript:void(0);">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="firstnameInput" class="form-label">First Name</label>
                                                    <input type="text" class="form-control" id="firstnameInput" placeholder="Enter your firstname"  name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}/>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="lastnameInput" class="form-label">Last Name</label>
                                                    <input type="text" class="form-control" id="lastnameInput" placeholder="Enter your lastname"   name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}/>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="phonenumberInput" class="form-label">Phone Number</label>
                                                    <input type="text" class="form-control" id="phonenumberInput" placeholder="Enter your phone number" name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleChange}/>
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="emailInput" class="form-label">Email Address</label>
                                                    <input type="email" class="form-control" id="emailInput" placeholder="Enter your email"  name="Email"
                          value={formData.email}
                        />
                                                </div>
                                            </div>
                                      
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="JoiningdatInput" class="form-label">User Type</label>
                                                
                        <select
                          className="form-select"
                          id="userTypeInput"
                          name="UserType"
                          value={formData.UserType}
                          onChange={handleChange}
                          required
                        >
                          <option value="customer">Customer</option>
                          <option value="client">Admin</option>
                          <option value="tailor">Tailor</option>
                        </select>
                                                </div>
                                            </div>
                                           
                                           
{/*                                        
                                          <div className="col-lg-6">
                                         { formData.UserType ==='tailor'  &&<div class="mb-3">
                                                    <label for="designationInput" class="form-label">Designation</label>
                                                    <input type="text" class="form-control" id="designationInput" placeholder="Designation" name='Designation' value={formData.Designation}
                          onChange={handleChange}/>
                                                </div>}
                                                  </div>                                         */}
                                            <div class="col-lg-12">
                                            { formData.UserType ==='tailor' && <div class="mb-3">
                                                    <label for="websiteInput1" class="form-label">Joining Date</label>
                                                    <input type="text" class="form-control" id="websiteInput1" placeholder="YYYY-MM-DD" name='joinDatetime'  value={formData.joinDatetime}
                          onChange={handleChange} />
                                                </div>}
                                            </div> 
                                       <div class="col-lg-4">
                                                <div class="mb-3">
                                                    <label for="cityInput" class="form-label">City</label>
                                                    <input type="text" class="form-control" id="cityInput" placeholder="City" name="city"
                          value={formData.city}
                          onChange={handleChange} />
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-4">
                                                <div class="mb-3">
                                                    <label for="countryInput" class="form-label">Country</label>
                                                    <input type="text" class="form-control" id="countryInput" placeholder="Country" name="country"
                          value={formData.country}
                          onChange={handleChange}/>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-4">
                                                <div class="mb-3">
                                                    <label for="zipcodeInput" class="form-label">Zip Code</label>
                                                    <input type="text" class="form-control" minlength="5" maxlength="6" id="zipcodeInput" placeholder="Enter zipcode" name="ZipCode"
                          value={formData.ZipCode}
                          onChange={handleChange}/>
                                                </div>
                                            </div>
                                       
                                            <div class="col-lg-12">
                                                <div class="mb-3 pb-2">
                                                    <label for="exampleFormControlTextarea" class="form-label">Address</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea" placeholder="Enter your address" rows="3" name="address"
                          value={formData.address}
                          onChange={handleChange}></textarea>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="hstack gap-2 justify-content-end">
                                                <button type="submit" className="btn btn-primary me-2" onClick={(e)=>{handleSubmit();e.preventDefault()}}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => navigate('/Customer', { replace: true })}>
                          Back to Order
                        </button>
                                                    <button type="button" class="btn btn-soft-success">Cancel</button>
                                                </div>
                                            </div>
                                         
                                        </div>
                                    
                                    </form>
                                </div>
                              
                                <div class="tab-pane" id="changePassword" role="tabpanel">
                                <table className="table align-middle table-nowrap">
      <thead className="text-muted table-light">
        <tr>
        <th>Order ID</th>
          <th>Name</th>
          <th>Product</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Assigned To</th>
          <th>Delivery Date</th>
         
        </tr>
      </thead>
      <tbody>
    {filteredOrders
      .filter(order => order.name === formData.firstName) // Filter orders for the current user
      .map((order, index) => (
        <tr key={index}>
          <td className='fw-medium link-primary'>{getOrderIdDisplay(order.id)}</td>
          <td>{order.name}</td>
          <td>{order.description || 'N/A'}</td>
          <td><span className='text-success'>{`$${calculateTotalPrice(order.price , order.quantity)}`}</span></td>
          <td><span className={getStatusClass(order.status)}>{order.status || 'N/A'}</span></td>
          <td>{order.assignedTo || 'N/A'}</td>
          <td>{formatDate(order.delieverDate) || 'N/A'}</td>
        </tr>
      ))}
  </tbody>
</table>
                             
                               
                                 
                                </div>

                              
                            </div>
                        </div>
                    </div>
                </div>
             
            </div>
          

        </div>
        
    </div>

    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                  <p className="mb-0 text-muted">&copy; Copyright to Tailor Solution 2024</p>
                </div>
                <div class="col-sm-6">
                  
                </div>
            </div>
        </div>
    </footer>
</div>
</div>
  )
}