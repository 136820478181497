import React from 'react'
import Header from '../Components/Header'
import Tailor from '../Components/Tailor'

export default function TailorPage() {
  return (
    <div>
        <div id="layout-wrapper">
            <Header/>
            <Tailor/>
</div>
    </div>
  )
}
