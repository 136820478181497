import React from 'react'
import Header from '../Components/Header'
import Customer from '../Components/Customer'

export default function Customerpage() {
  return (
    <div> <div><div id="layout-wrapper">
    <Header/>
    <Customer/>
    </div>
</div></div>
  )
}
