import React , { useEffect }from 'react'
import './App.css';
import { Route, Routes} from 'react-router-dom'
import Login from './Components/login';
import ForgetPassword from './Components/ForgetPassword';
import Signup from './Components/Signup';
import TailorPage from './Pages/TailorPage';
import Dashboardpage from './Pages/Dashboardpage';
import Orderpage from './Pages/Orderpage';
import Servicespage from './Pages/Servicespage';
import Customerpage from './Pages/Customerpage';
import { I18nextProvider } from 'react-i18next';
import  i18n from './Localization/I18next';
import Profilepage from './Pages/Profilepage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NewOrder from './Pages/NewOrder';
import TailorOrder from './Pages/TailorOrder';
import Crmpage from './Pages/Crmpage';
import CRM from './Components/CRM';
import ResetPassword from './Components/ResetPassword';
function App() {
  const clientId = '299878190698-b5r9f37g1s1lg4omhmgpsepc1h7q1j9j.apps.googleusercontent.com'; 
  // Replace with your Google Client ID
  return (
  
    <GoogleOAuthProvider clientId={clientId}>
    <div>
    <I18nextProvider i18n={i18n}>
      <Routes>
            <Route path={"/"} element={<Login />} />
            <Route index path={"/Login"} element={<Login />} />
            <Route index path={"/signup"} element={<Signup/>} />
              <Route path={"/forgetpassword"} element={<ForgetPassword />} />
               <Route path={"/order"} element={<Orderpage />} />
               <Route path={"/Dash"} element={<Dashboardpage/>} />
               <Route path={"/service"} element={<Servicespage/>} />
               <Route path={"/Tailor"} element={<TailorPage/>} />
               <Route path={"/Customer"} element={<Customerpage/>} />
               <Route path={"/Profile"} element={<Profilepage/>} />
               <Route path={"/NewOrder"} element={<NewOrder/>} />
               <Route path={"/tailorOrder"} element={<TailorOrder/>} />
               <Route path={"/customers/:userId"} element={<Crmpage/>} />
               <Route path="/resetpassword" element={<ResetPassword/>} />



               
            {/* <Route path={"/forgetpassword"} element={<ForgetPassword />} />
            <Route path={"/admin/dashboard/default"} element={<DASHBOARD />} />
            <Route path={"/admin/dashboard/User"} element={<USER />} /> */}
        </Routes>
        </I18nextProvider>
    </div>
    </GoogleOAuthProvider>
 
  );
}

export default App;
