import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Doorder( ) {

    const [services, setServices] = useState([]);
  const [tailorUsers, setTailorUsers] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [customerUsers, setCustomerUsers] = useState([]);
  const [userType, setUserType] = useState('');
  const [orders, setOrders] = useState([]);
  const token = Cookies.get('accessToken');

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userid; // Adjust based on your token structure
    } catch (error) {
      console.error('Error decoding access token:', error);
      return null;
    }
  };
  const handleServiceChange = (e) => {
    const selectedServiceId = e.target.value;
    const selectedService = services.find((service) => service.id === selectedServiceId);
    if (selectedService) {
      setNewUserData((prevData) => ({
        ...prevData,
        description: selectedService.name,
        price: selectedService.price,
      }));
    }
  };
  useEffect(() => {
    const fetchInitialData = async () => {
      if (!token) {
        console.error('Access token not found');
        return;
      }
  
      try {
        const userId = getUserIdFromToken(token);
        if (!userId) {
          console.error('Failed to extract user ID from access token.');
          return;
        }
      
  
        const [servicesResponse, userResponse, ordersResponse] = await Promise.all([
          axios.get('https://tailors-api.azurewebsites.net/api/Users/api/services', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`https://tailors-api.azurewebsites.net/api/Users/${userId}`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getorder', { headers: { Authorization: `Bearer ${token}` } }),
        ]);
  
        // Filter the services data if necessary
        const filteredServices = servicesResponse.data.map(service => ({
          id: service.id,
          name: service.name,
          price: service.price,
        }));
  
        setServices(filteredServices);
        setUserType(userResponse.data.firstName);
        setOrders(ordersResponse.data);
        setFilteredOrders(ordersResponse.data);
  
        // Fetch tailor and customer users separately to minimize fetched data
        const [tailorUsersResponse, customerUsersResponse] = await Promise.all([
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getall', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('https://tailors-api.azurewebsites.net/api/Users/Getall', { headers: { Authorization: `Bearer ${token}` } }),
        ]);
  
        setTailorUsers(tailorUsersResponse.data.filter(user => user.userType === 'tailor'));
        setCustomerUsers(customerUsersResponse.data.filter(user => user.userType === 'customer'));
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };
  
    fetchInitialData();
  }, [token]);
  
    const [newUserData, setNewUserData] = useState({
        name: '',
        description: '',
        assignedTo: '',
        price: '',
        quantity: '',
        delieverDate: '',
        status: '',
      });
      const getOrderIdDisplay = (id) => {
        // Assuming the ID is a GUID, you can slice the first 3 characters for display
        return `#${id.slice(0, 6)}`;
      };
      const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      };
    
      const getStatusClass = (status) => {
        switch (status) {
          case 'not-started':
            return 'badge bg-secondary-subtle text-secondary';
          case 'Pending':
            return 'badge bg-warning-subtle text-warning';
          case 'Complete':
            return 'badge bg-success-subtle text-success';
          case 'Rejected':
            return 'badge bg-danger-subtle text-danger';
          default:
            return '';
        }
      };
    
     
      const handleAddUser = async () => {
        try {
          if (!token) {
            console.error('Access token not found');
            return;
          }
          const response = await axios.post('https://tailors-api.azurewebsites.net/addOrder', newUserData, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setOrders((prevOrders) => [...prevOrders, response.data]);
          setNewUserData({
            name: '',
            description: '',
            assignedTo: '',
            price: '',
            quantity: '',
            delieverDate: '',
            status: '',
          });
        toast.success('Order is created Successfully')
        } catch (error) {
          console.error('Error adding order:', error);
          toast.error('Error adding order');
        }
      };
  

  
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUserData((prevData) => ({ ...prevData, [name]: value }));
      };
   
    

  const calculateTotalPrice = (price, quantity) => {
    const totalPrice = price * quantity;
    return isNaN(totalPrice) ? 'N/A' : totalPrice;
  };
   
    
  return (
    <div>    
          <ToastContainer />
         <div class="main-content">

    <div class="page-content">
        <div class="container-fluid">

            

            <div class="row">
               
            
                <div class="h-100">
                <div class="card mt-n5">
                <div class="card-body p-4"> <div class="text-center">
                                <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                                   <h1> ORDER NOW</h1>
                                   
                                </div>
                               
                            </div>
                    </div></div>
                    <div class="card mt-xxl-n5">
                    <div class="card-header">
                            <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                        <i class="fas fa-home"></i> Order Details
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                        <i class="far fa-user"></i>Order status
                                    </a>
                                </li>
                               
                            </ul>
                        </div>
                        <div class="card-body p-4">
                            <div class="tab-content">
                                <div class="tab-pane active" id="personalDetails" role="tabpanel">
                                    <form action="javascript:void(0);">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                <label
                        htmlFor="name"
                        className="form-label"
                      >
                        Name
                      </label>
                      <select id="tailorSelect"     name='name' className="form-select"  value={newUserData.name} onChange={handleInputChange} >
        <option value="">Select a Customer</option>
        {customerUsers.map((user) => (
          <option key={user.id} value={user.firstName}>{user.firstName}</option>
        ))}
      </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                <label
                        htmlFor="email"
                        className="form-label"
                      >
                        Product
                      </label>
                      <select
            id="serviceSelect"
            name="description"
            className="form-select"
            value={newUserData.description}
            onChange={handleServiceChange}
          >
            <option value="">Select a Service</option>
            {services.map((service) => (
              <option key={service.id} value={service.id}>{service.name}</option>
            ))}
          </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                <label
                        htmlFor="email"
                        className="form-label"
                      >
                        Price
                      </label>
                      <input
            type="text"
            className="form-control"
            id="price"
            name="price"
            value={newUserData.price}
            readOnly
          />
                                                </div>
                                            </div>
                                           
                                      
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                <label htmlFor="quantity" className="form-label">
            Quantity
          </label>
          <input
            type="number"
            className="form-control"
            id="quantity"
            name="quantity"
            value={newUserData.quantity}
            onChange={handleInputChange}
          />
                                                </div>
                                            </div>
                                           
                                 
                                            <div class="col-lg-12">
                                            <label
                          htmlFor="name"
                          className="form-label"
                        >
                          Delivery Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="delieverDate"
                          value={newUserData.delieverDate}
                          onChange={handleInputChange}
                        />
                                            </div> 
                                
                                         
                                          
                                        
                                       
                                         
                                         
                                            <div class="col-lg-12">
                                                <div class="hstack gap-2 justify-content-end">
                                                <button type="submit" className="btn btn-primary me-2" onClick={(e)=>{handleAddUser()}}>Save Order</button>
                       
                                                    <button type="button" class="btn btn-soft-success">Cancel</button>
                                                </div>
                                            </div>
                                         
                                        </div>
                                    
                                    </form>
                                </div>
                                <div class="tab-pane" id="changePassword" role="tabpanel">
                                <table className="table align-middle table-nowrap">
      <thead className="text-muted table-light">
        <tr>
        <th>Order ID</th>
          <th>Name</th>
          <th>Product</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Assigned To</th>
          <th>Delivery Date</th>
         
        </tr>
      </thead>
      <tbody>
    {filteredOrders
      .filter(order => order.name === userType) // Filter orders for the current user
      .map((order, index) => (
        <tr key={index}>
          <td className='fw-medium link-primary'>{getOrderIdDisplay(order.id)}</td>
          <td>{order.name}</td>
          <td>{order.description || 'N/A'}</td>
          <td><span className='text-success'>{`$${calculateTotalPrice(order.price , order.quantity)}`}</span></td>
          <td><span className={getStatusClass(order.status)}>{order.status || 'N/A'}</span></td>
          <td>{order.assignedTo || 'N/A'}</td>
          <td>{formatDate(order.delieverDate) || 'N/A'}</td>
        </tr>
      ))}
  </tbody>
</table>

                                </div>
                      

                              
                              
                            </div>
                        </div>
                    </div>
                </div>
             
            </div>
          

        </div>
        
    </div>

    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                <p className="mb-0 text-muted">&copy; Copyright to Tailor Solution 2024</p>
                </div>
                <div class="col-sm-6">
                  
                </div>
            </div>
        </div>
    </footer>
</div>
</div>
  )
}
